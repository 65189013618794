import React from "react";
import {request} from "../../../api-client";
import "layouts/Home/Home.css";
import "./NavBar.css";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import PlayArrow from "@material-ui/icons/PlayArrow";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import Logo from "../../../assets/img/logo-black.png";
import LogoWhite from "../../../assets/img/logo-white.png";
import TextField from "@material-ui/core/TextField/TextField";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {
  getMostImportantAlbum,
  makeFitImageUrl,
  makeUrlImageFromAlbums,
  makeWaveformUrl,
  pushIfDoesNotExist,
} from "../../../utils";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  catalogClientView,
  getSignatureTime,
  getStoredUser,
  channelPathGenerator,
  userCanDownloadTracks,
  userisMultiClient,
  userIsSupervisor,
  userIsSysAdmin,
  addHttpsToUrl,
  getStoredChannel,
  slugify,
  rootPath,
  getActiveSection,
  getUseTools,
  getChannelCanLicense,
  checkIfMobile, getMainTranslation,
  getTitleByLang,
  userIsContentSupervisor,
  userHasRestrictedAccess
} from "../../../api-client/core/authentication/utils";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Popover from "@material-ui/core/Popover";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import analyticsInstance from "../../../analytics/analyticsInstance";
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";
import {makeImageUrlWithSize, emptyImage} from "../../Home/HomeElements/utils";
import browserHistory from "../../../utils/browserHistory";
import MenuIcon from "@material-ui/icons/Menu";
import availableLanguages from "../../../config/localization/availableLanguages.json";
import moment from "moment";
import GlobalRedirectLoginConfirmationDialog from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import { Category, StarBorder } from "@material-ui/icons";
import { Dialog, Tooltip } from "@material-ui/core";
import EventManager, {events} from "../../../utils/EventManager";
import Contact from "../../Home/Contact";
import RadioPlayer from "./RadioPlayer";
import { navigateToCurrentLangRoute } from "../../../routes/channel";
import {get} from "lodash"
import MobileSidebar from "./MobileSidebar";
import ProfileForm from "../Profile";
import DefaultAvatar from "../../../assets/img/default-avatar.png";
import Tag from "../../../components/Letflow/Tag";
import styled from "styled-components";

const PersonImage = styled.img`
	width: 50px;
	height: 50px;
	object-fit: cover;
	object-position: ${props => {
		if (props.boundingBox) {
			const x = props.boundingBox.Left * 100;
			const y = props.boundingBox.Top * 100;
			return `${x}% ${y}%`;
		}
		return 'center';
	}};
	background-color: #f0f0f0;
  border-radius: 50%;
`;

class channelNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: false,
      search: false,
      showSearchItems: false,
      searchInput: null,
      open: false,
      openNotification: false,
      openLangDropdown: false,
      channel: props.channel,
      countPendingReceipt: 0,
      conversationsWithUnreadMessages: 0,
      language: localization.getLanguage(),
      width: window.innerWidth,
      showCreationDialog: false,
      navbarElements: props.navbarElements.filter(element => element.data_source !== "sub_navbar"),
      subNavbarElement: props.subNavbarElement,
      subNavbarElements: props.subNavbarElements,
      showLiveButton: false,
      livestreamData: null,
      makeLivestreamCheckRequest: true,
      musics: [],
      contentCreators: [],
      contentCreatorsTags: [],
      contentCreationsTags: [],
      showContactDialog: false,
      selectedElement: false,
      availableLanguages: props.channel ? props.channel.languages.map(lang => lang.language) : availableLanguages,
      openEditDialog: false,
      isAnyMenuOpen: false
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.setWindowsWidth);
    this.checkForLiveEvents()
    this.checkCurrentLocation(this.props.location.pathname);
    this.checkForLiveEventsTimeout = setInterval(
      () => this.checkForLiveEvents(),
      180000
    );
  };

  

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setWindowsWidth)
    clearInterval(this.checkForLiveEventsTimeout);
  };

  getIdFromUrl = (pathname , matcher) => {
    if (!pathname.includes(`/${matcher}/`)) return false;
    let homeData = pathname.split(`/${matcher}/`)[1]

    return Number(homeData && homeData.split("-")[0])
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkCurrentLocation(this.props.location.pathname);
    }
  };

  checkCurrentLocation = (pathname) => {
    const navigableNavbarElement = this.state.navbarElements.filter(element => ['section', 'my_list', 'content_creator', 'single_content_creator', 'catalog', 'drop_down', 'playlist', 'contact', 'home'].includes(element.data_source));
    let selectedElementId = null;
    let element = null;

    if (pathname.includes('browse')) {
      selectedElementId = this.getIdFromUrl(pathname, 'browse')
      element = navigableNavbarElement.find(element => element.id === selectedElementId);
    }else if (pathname.includes('contacto')) {
      element = navigableNavbarElement.find(element => element.data_source === 'contact_form');
    }else if (pathname.includes('mi-lista')) {
      element = navigableNavbarElement.find(element => element.data_source === 'my_list');
    }else if (pathname.includes('busqueda-inteligente')) {
      element = navigableNavbarElement.find(element => element.data_source === 'smart_search');
    }else if (navigableNavbarElement.find(element => element.data_source === 'single_content_creator')) {
      const elements = navigableNavbarElement.filter(element => element.data_source === 'single_content_creator')
      selectedElementId = this.getIdFromUrl(pathname, 'contenido')

      element = elements.find(el => get(el,"metadata.content_creator_id") == selectedElementId)
    }else if (pathname.includes('home')) {
      selectedElementId = this.getIdFromUrl(pathname, 'home')
      element = navigableNavbarElement.find(element => element.metadata.target_section_id === selectedElementId)
    }else if (navigableNavbarElement.find(element => element.data_source === 'home')) {
      element = navigableNavbarElement.find(element => element.data_source === 'home')
    }

    if(element) this.setState({ selectedElement: element.parent || element.id})
  }

  getIdFromUrl = (pathname , matcher) => {
    if (!pathname.includes(`/${matcher}/`)) return false;
    let homeData = pathname.split(`/${matcher}/`)[1]

    return Number(homeData && homeData.split("-")[0])
  }

  setWindowsWidth = () => this.setState({width: window.innerWidth});

  checkForLiveEvents = () => {
    if (this.state.makeLivestreamCheckRequest) {
      request.liveStream.checkIfActive(this.props.channel.id)
        .then(livestreams => {
          if (livestreams.length > 0) {
            this.setState({livestreamData: livestreams[0]})
          } else {
            this.setState({livestreamData: null})
          }
        })
        .then(() => {
          if (this.state.livestreamData) {
            let timeDiffToEnd = moment.utc(this.state.livestreamData.end_time).diff(moment().local(), "seconds")
            this.setState({makeLivestreamCheckRequest: false},
              () => setTimeout(() => this.setState({makeLivestreamCheckRequest: true}), Math.abs(timeDiffToEnd*1000)))

            if (this.state.livestreamData.fake) {
              this.setState({showLiveButton: true})
            } else {
              let timeDiff = moment().diff(moment.utc(this.state.livestreamData.start_time).local(), "seconds")
              if (timeDiff < 0 ) {
                setTimeout(() => this.setState({showLiveButton: true}), Math.abs(timeDiff*1000))
              } else {
                this.setState({showLiveButton: true})
              }
            }
          }
        })
    }
  }

  toggleSearch = open => {
    if (this.state.currentPlayingContent && this.state.currentPlayingContent.id) {
      return;
    }
    this.setState({ search: open }, () => setTimeout(() => this.setState({showSearchItems: open}), 200));
    if (open) {
      setTimeout(() => this.searchInput.focus(), 300);
    } else {
      this.setState({
        searchInput: "",
        musics: [],
        albums: [],
        playlists: [],
        savedQueries: [],
        searchResultComponents: [],
        contentCreators: [],
        contentCreatorsTags: [],
        contentCreationsTags: [],
        people: [],
        showSearchItems: false,
      });
    }
  };

  handleSearchInputOnChange = e => {
    this.setState({ searchInput: e.target.value });
  };

  handleSearchInputKeyPress = e => {
    if (e.key === "Enter") {
      if (this.state.searchInput.length > 2) {
        this.handleSearch(this.state.searchInput);
      } else {
        GlobalSnackbar.show({ message: localization.get("searchbar.min_letters"), type: GlobalSnackbarTypes.ERROR });
      }
    }
  };

  handleSearch = query => {
  /*
  */
    analyticsInstance.glassSearch(query);

    request.user.globalSearch(this.state.channel.client.id, query, this.state.channel.id)
      .then((response) => {
        this.setState({
          playlists: response.playlists || [],
          albums: response.albums || [],
          artists: response.artists || [],
          musics: response.musics || [],
          voices: response.voices || [],
          contentCreators: response.contentCreators || [],
          contentCreations: response.contentCreations || [],
          reelFiles: response.reel_files || [],
          driveFiles: response.drive_files || [],
          contentCreatorsTags: response.contentCreatorsTags || [],
          contentCreationsTags: response.contentCreationsTags || [],
          people: response.people || [],
        })
      })
      .then(() => this.appendSearchResult())
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open, openNotification: false });
  };

  handleToggleLangDropdown = () => {
    this.setState({ openLangDropdown: !this.state.openLangDropdown });
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  validateLoginRedirect = path => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: this.state.channel.login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      browserHistory.push(path)
    }
  }


  checkPermissions = (entity) => this.state.channel.client.permissions.some(permission => permission.entity === entity);

  handlePlayButtonPress = (track, tracks, type, trackContainer) => {
    const soundbarCompatibleTracks = tracks.map(track => {
      const onToggleFavorite = favorite => request.musicFavorite[favorite ? "add" : "remove"](track.id);

    
      analyticsInstance.playContent({
        name: getMainTranslation(track, "title"),
        type: "Audio",
        content: trackContainer && getMainTranslation(trackContainer, "name")
      })
      return {
        fetchAudioUrl: () => new Promise(resolve => {
          let url = '';
          const date = new Date()
          const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

          if((now_utc - Date.parse(track.time)) < getSignatureTime()) {
            url = track.audio.url
          }else{
            url = request.music.getForClient(track.id).then(track => track.audio.url)
          }

          resolve(url);
        }),
        id: track.id,
        title: track.title,
        subtitle: trackContainer && (trackContainer.title ? trackContainer.title : trackContainer.name ? trackContainer.name : undefined),
        image: this.makeSoundbarTrackImage(trackContainer),
        waveform: makeWaveformUrl(track),
        onDownload: userCanDownloadTracks() ? () => this.openDownloadDialog(track, trackContainer, type) : undefined,
        onTitleClick:
          type === "music"
            ? () => this.props.history.push(channelPathGenerator(`musica/${track.id}-${slugify(track.title)}`))
            : undefined,
        onSubtitleClick: () => this.handleTrackContainerPressedInSoundbar(trackContainer, type),
        isFavorite: track.favorite,
        onToggleFavorite: type === "music" && onToggleFavorite,
      };
    });
    const playableTrackIndex = tracks.indexOf(track);

    GlobalSoundBar.loadTracksAndPlay(soundbarCompatibleTracks, playableTrackIndex);
  };

  addTrackContainer = (track, tracks, type) => {
    const audioRequest = type === 'voice' ? request.voice.get(track.id) : request.music.getForClient(track.id)

    audioRequest.then(trackContainer =>
      this.handlePlayButtonPress(
        track,
        tracks,
        type,
        trackContainer.albums
          ? getMostImportantAlbum(trackContainer.albums)
          : trackContainer.speaker
          ? trackContainer.speaker
          : undefined
      )
    )
  };

  playCreation = creation => {
    
      this.setState({currentPlayingContent: creation, showContentCreationDialog: true})
  }

  makeSoundbarTrackImage = trackContainer => {
    if (trackContainer.length > 0) {
      return this.makeUrlImageFromAlbums(trackContainer);
    } else {
      return makeImageUrlWithSize(trackContainer, 'xs');
    }
  };

  makeUrlImageFromAlbums = albums => makeUrlImageFromAlbums(albums);

  openDownloadDialog = (track, trackContainer, type) => {
    GlobalConfirmationDialog.show({
      title: localization.get("download.demo"),
      content: localization.get("download.demo.content"),
      request: () => this.handleDownload(track, trackContainer, type),
      confirmationLabel: localization.get("download")
    });
  };

  handleDownload = (track, trackContainer, type) => {
    this.setState({ downloading: true });
    let downloadType = type === "voice" ? "download" : "downloadDemo";
    return request[type][downloadType](track.id, {
      title: track.title,
      image: this.makeSoundbarTrackImage(track, trackContainer)
    })
      .then(() => {
        this.setState({ downloading: false });
      })
      .catch(() => {
        this.setState({ downloading: false });
      });
  };

  handleTrackContainerPressedInSoundbar = (trackContainer, type) => {
    let destinationPath = this.makeTrackContainerPathFrom(trackContainer, type);
    if (destinationPath) {
      this.props.history.push(destinationPath);
    }
  };

  makeTrackContainerPathFrom = (trackContainer, type) => {
    let destinationPath;
    if (trackContainer && type === "music") {
      destinationPath = channelPathGenerator(`album/${trackContainer.id}`);
    }
    return destinationPath;
  };

  appendSearchResult = () => {
    const { musics, albums, contentCreators, contentCreations, artists, channel, contentCreationsTags, contentCreatorsTags, people } = this.state;
    let contentCreatorTypes = [];
    let searchResultComponents = [];
    let materialsContentCreatorTypes = [];
    let hasResults = true;
    const channelCategories = this.state.channel.channelContentCreatorTypes.filter(type => type.show_on_smart_search).map(category => category.contentCreatorType);

    if (
      musics.length > 0 ||
      albums.length > 0 ||
      artists.length > 0 ||
      contentCreators.length > 0 ||
      contentCreations.length > 0 ||
      contentCreationsTags.length > 0 ||
      contentCreatorsTags.length > 0 ||
      people.length > 0
    ) {
      if (channelCategories.length > 0 && people.length > 0) {
        channelCategories.forEach(contentCreatorType => {

          searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <h4 style={{color: "var(--navbar-text-color, black)"}}>{contentCreatorType.name} ({localization.get('people')})</h4>
              <div style={{display: "flex"}}>
                {people.filter(person => person.content_creator_type_id === contentCreatorType.id)
                .map(person => {
                  return (
                    <Tag 
                      onClick={() => {
                        EventManager.getInstance().dispatch(events.EXPLORE_NAVBAR_SEARCH);
                        this.toggleSearch(false)
                        browserHistory.push(channelPathGenerator(`busqueda-inteligente/content_creations/${contentCreatorType.id}-${slugify(contentCreatorType.name)}?type=${contentCreatorType.slug}&people_ids=${person.id}`))
                      }}
                      title={
                        <div >
                          {/* <PersonImage 
                            src={makeFitImageUrlWithSize({image: person.main_image}, 'xl')} 
                            alt={person.name || 'Sin nombre'}
                            boundingBox={person.main_image.pivot.face_metadata.boundingBox}
                          style={calculateImageStyle({...person.main_image, face_metadata: person.main_image.pivot.face_metadata})}
                        /> */}
                          {person.name}
                        </div>}
                      color={"var(--navbar-text-color, black)"}
                      backgroundColor={"var(--secondary-color, white)"}
                    />
                  )}
                )}
              </div>
            </div>
          );
        })
      }

      if (musics.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4 style={{color: "var(--navbar-text-color, black)"}}>{localization.get("searchbar.musics")}</h4>
            {musics.map(music => (
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px",
                  color: "var(--navbar-text-color, black)",
                }}
              >
                <Link
                  onClick={() => this.toggleSearch(false)}
                  to={channelPathGenerator(`musica/${music.id}-${slugify(music.title)}`)}
                  style={{
                    width: "calc(100% - 30px)",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: "var(--navbar-text-color, black)",
                  }}
                >
                  {music.title}
                </Link>
                <IconButton
                  onClick={() => this.addTrackContainer(music, musics, "music")}
                  style={{ float: "left", position: "relative", width: "30px", height: "30px", top: "-6px" }}
                >
                  <PlayArrow style={{color: "var(--navbar-text-color, black)"}}/>
                </IconButton>
              </div>
            ))}
          </div>
        );
      }

      if (albums.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4 style={{color: "var(--navbar-text-color, black)"}}>{localization.get("searchbar.albums")}</h4>
            {albums.map(album => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={channelPathGenerator(`album/${album.id}`)}
                style={{
                  marginTop: "10px",
                  color: "var(--navbar-text-color, black)",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(album, 'xs')}), url(${emptyImage})`}}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: "var(--navbar-text-color, black)"
                  }}
                >
                  {album.title}
                </div>
              </Link>
            ))}
          </div>
        );
      }

      if (artists.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4 style={{color: "var(--navbar-text-color, black)"}}>{localization.get("searchbar.artists")}</h4>
            {artists.map(artist => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={channelPathGenerator(`artista/${artist.id}-${slugify(artist.name)}`)}
                style={{
                  marginTop: "10px",
                  color: "var(--navbar-text-color, black)",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(artist.profile ? {image: artist.profile} : artist, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: "var(--navbar-text-color, black)"
                  }}
                >
                  {artist.name}
                </div>
              </Link>
            ))}
          </div>
        );
      }
      if (contentCreators.length > 0) {
        contentCreatorTypes = [];
        contentCreators.forEach(contentCreator => pushIfDoesNotExist(contentCreatorTypes, contentCreator.type));

        contentCreatorTypes.forEach(contentCreatorType => {
          const channelCategory = (getStoredChannel().channelContentCreatorTypes.find(type => type.contentCreatorType && type.contentCreatorType.id === contentCreatorType.id))
          const searchTarget = channelCategory.allow_event_search ? 'eventos' :  'contenido';

          searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <Link
                onClick={() => {
                  EventManager.getInstance().dispatch(events.EXPLORE_NAVBAR_SEARCH);
                  this.toggleSearch(false)
                }}
                to={channelPathGenerator(`busqueda-inteligente/${searchTarget}/${contentCreatorType.id}-${slugify(contentCreatorType.name)}?type=${contentCreatorType.slug}&name=${this.state.searchInput}&disablePublishDate=null`)}
              >
                <h4 style={{color: "var(--navbar-text-color, black)", display: 'flex', alignItems: 'center'}}>{contentCreatorType.name} {window.innerWidth > 600 ? <span style={{textDecoration: "underline", fontSize: 14, marginLeft: 10}}>{localization.get('navbar.explore')}</span> : <Search style={{marginLeft: 10}}/>}</h4>
              </Link>
              {contentCreators.filter(contentCreator => contentCreator.type.id === contentCreatorType.id).map(contentCreator => (
                <Link
                  onClick={() => this.toggleSearch(false)}
                  to={channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}?origin=Lens`)}
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                    float: "left",
                    position: "relative",
                    height: "15px",
                    color: "var(--navbar-text-color, black)"
                  }}
                >
                  <div
                    className="searchbar-result-row-image"
                    style={{backgroundImage: `url(${makeImageUrlWithSize(contentCreator, 'xs')}), url(${emptyImage})`}}
                  />
                  <div
                    style={{
                      width: "70%",
                      float: "left",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      color: "var(--navbar-text-color, black)"
                    }}
                  >
                    {contentCreator.name}
                  </div>
                </Link>
              ))}
            </div>
          );
        })
      }
      if (contentCreations.length > 0) {
        contentCreations.forEach(contentCreation => pushIfDoesNotExist(materialsContentCreatorTypes, contentCreation.contentCreator.type));
        materialsContentCreatorTypes.forEach(contentCreatorType => {
          searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <Link
                onClick={() => {
                  EventManager.getInstance().dispatch(events.EXPLORE_NAVBAR_SEARCH);
                  this.toggleSearch(false)
                }}
                to={channelPathGenerator(`busqueda-inteligente/content_creations/${contentCreatorType.id}-${slugify(contentCreatorType.name)}?type=${contentCreatorType.slug}&name=${this.state.searchInput}&disablePublishDate=null`)}
              >
                <h4 style={{color: "var(--navbar-text-color, black)"}}>{contentCreatorType.name} ({localization.get('content_creations.materials')}) <span style={{textDecoration: "underline", fontSize: 14}}>{localization.get('navbar.explore')}</span></h4>
              </Link>

              {contentCreations.filter(contentCreation => contentCreation.contentCreator.type.id === contentCreatorType.id).map(contentCreation => (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                    float: "left",
                    position: "relative",
                    height: "15px",
                    color: "var(--navbar-text-color, black)"
                  }}
                >
                  <div
                    style={{
                      width: "calc(100% - 30px)",
                      float: "left",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      color: "var(--navbar-text-color, black)"
                    }}
                  >
                    {contentCreation.title}
                  </div>
                  <IconButton
                    onClick={() => this.playCreation(contentCreation)}
                    style={{float: "left", position: "relative", width: "30px", height: "30px", top: "-6px", color: "var(--navbar-text-color, black)"}}
                  >
                    <PlayArrow/>
                  </IconButton>
                </div>
              ))}
            </div>
          );
        })
      }
      if (channelCategories.length > 0 && contentCreationsTags.length > 0) {
        channelCategories.forEach(contentCreatorType => {
          const tagsCount = contentCreationsTags.filter(tag => tag.group.types.some(type => type.type === contentCreatorType.slug)).length;
          if (tagsCount> 0)  searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <h4 style={{color: "var(--navbar-text-color, black)"}}>{contentCreatorType.name} ({localization.get('content_creations.materials_tags')}) <span style={{textDecoration: "underline", fontSize: 14}}>{localization.get('navbar.explore')}</span></h4>
              <div style={{display: "flex"}}>
                {contentCreationsTags.filter(tag => tag.group.types.some(type => type.type === contentCreatorType.slug)).map(tag => {
                  return (
                    <Tag 
                      onClick={() => {
                        EventManager.getInstance().dispatch(events.EXPLORE_NAVBAR_SEARCH);
                        this.toggleSearch(false)
                        browserHistory.push(channelPathGenerator(`busqueda-inteligente/content_creations/${contentCreatorType.id}-${slugify(contentCreatorType.name)}?type=${contentCreatorType.slug}&tags_ids=${tag.id}`))
                      }}
                      title={tag.name}
                      color={"var(--navbar-text-color, black)"}
                      backgroundColor={"var(--secondary-color, white)"}
                    />
                  )}
                )}
              </div>
            </div>
          );
        })
      }
      if (channelCategories.length > 0 && contentCreatorsTags.length > 0) {
        channelCategories.forEach(contentCreatorType => {
          const tagsCount = contentCreatorsTags.filter(tag => tag.group.types.some(type => type.type === contentCreatorType.slug)).length;
          if (tagsCount> 0) searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <h4 style={{color: "var(--navbar-text-color, black)"}}>{contentCreatorType.name} ({localization.get('content_creators.tags')}) <span style={{textDecoration: "underline", fontSize: 14}}>{localization.get('navbar.explore')}</span></h4>
              {contentCreatorsTags.filter(tag => tag.group.types.some(type => type.type === contentCreatorType.slug))
                .map(tag => (
                  <Tag 
                    onClick={() => {
                      EventManager.getInstance().dispatch(events.EXPLORE_NAVBAR_SEARCH);
                      this.toggleSearch(false)

                      browserHistory.push(channelPathGenerator(`busqueda-inteligente/contenido/${contentCreatorType.id}-${slugify(contentCreatorType.name)}?type=${contentCreatorType.slug}&tags_ids=${tag.id}`))
                    }}
                    title={tag.name}
                    color={"var(--navbar-text-color, black)"}
                    backgroundColor={"var(--secondary-color, white)"}
                  />
                ))}
            </div>
          );
        })
      }
     } else {
      hasResults = false;
      searchResultComponents.push(
        <div>
          <p style={{paddingTop: 50, textAlign: "center", color: "var(--navbar-text-color, black)"}}>{localization.get("searchbar.no_result")}</p>
        </div>
      );
    }
    this.setState({ searchResultComponents: searchResultComponents, isEmpty: !hasResults });
  };

  hasElevatedPrivileges = () => userIsSupervisor() || userIsSysAdmin() || userisMultiClient();

  isSelected = route => {
    if (route === "home") {
      if (window.location.pathname.split("/").length <= 3) {
        return true;
      }
    } else {
      return window.location.pathname.includes(route);
    }
  };

  isProfileUrl = () => {
    return window.location.pathname.includes('profile');
  }

  makeNavbarButton = (element, currentSelected, divClass = "channel-navbar-link") => {
    
    switch (element.data_source) {
      case "my_list":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            to={'#'}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.validateLoginRedirect(channelPathGenerator('mi-lista'))

            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>
        )
      case "playlist_link":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={'#'}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.validateLoginRedirect(channelPathGenerator('playlists'))
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "link":
        const domain = window.location.hostname.replace('www.','');
        let isSameDomain = element.metadata.link.startsWith(`${window.location.protocol}//${window.location.hostname}`) || element.metadata.link.startsWith(`${window.location.protocol}//${domain}`);
        let linkUrl = isSameDomain ? channelPathGenerator(element.metadata.link.split('//')[1].split('/').slice(1).join('/')) : addHttpsToUrl(element.metadata.link)
        let isCurrentLocation = false;
        if (isSameDomain) {
          isCurrentLocation = linkUrl == `${window.location.pathname}${window.location.search}`
        }
        return(
          <Link
            className={`${divClass} ${isCurrentLocation ? 'selected-navbar-button' : ''}`}
            to={linkUrl}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            rel="nofollow"
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              if (element.new_tab || !isSameDomain) {
                e.preventDefault();
              }
            
              if (!element.new_tab) {
                if (!isSameDomain) {
                  window.location.href = addHttpsToUrl(element.metadata.link)
                }else {
                  browserHistory.push(channelPathGenerator(element.metadata.link.split('//')[1].split('/').slice(1).join('/')))
                }
              } else {
                window.open(addHttpsToUrl(element.metadata.link), '_blank').focus();
              }
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "section":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={rootPath(`/home/${element.metadata.target_section_id}-${slugify(element.metadata.target_section_name).replace(/-/g, '_')}`)}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              e.preventDefault()
              browserHistory.push(rootPath(`/home/${element.metadata.target_section_id}-${slugify(element.metadata.target_section_name).replace(/-/g, '_')}`))
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "contact_form":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={'#'}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
            
              this.setState({showContactDialog: true});
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "home":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={rootPath()}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              if(getActiveSection()) {
                e.preventDefault();
                browserHistory.push(rootPath())
                EventManager.getInstance().dispatch(events.SECTION_CHANGED);
              }
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "content_creator":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "catalog" :
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "video_content_creation":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "single_content_creator":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={channelPathGenerator(`contenido/${element.content_creator_id}-${slugify(element.content_creator_name)}?origin=${encodeURIComponent(getMainTranslation(element, "title", true))}`)}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "smart_search":
        return(
            <Link
              className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
              style={divClass != "channel-navbar-link" ? {
                backgroundColor: element.background_color,
                color: element.font_color, borderColor: element.font_color, 
                ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
              } : {}}
              to={channelPathGenerator(`busqueda-inteligente`)}
              onClick={e => {
                analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              
              }}
            >
              {getTitleByLang(element.translations, this.state.language, element.title)}
            </Link>
          )
      case "channel_playlist":
        return(
          <Link
            className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            style={divClass != "channel-navbar-link" ? {
              backgroundColor: element.background_color,
              color: element.font_color, borderColor: element.font_color, 
              ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
            } : {}}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
      case "drop_down":
        if (this.state.width <= 600) {
          return(
            <Link
              aria-owns={this.state[element.id] ? "menu-list-grow" : null}
              aria-haspopup="true"
              aria-describedby={element.id} 
              buttonRef={node => {
                this[element.id] = node;
              }}
              className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
              to="#"
              style={divClass != "channel-navbar-link" ? {
                backgroundColor: element.background_color,
                color: element.font_color, borderColor: element.font_color, 
                ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
              } : {}}
              onClick={e => {
                if (!this.state[element.id]) this.setState({[element.id]: true, isAnyMenuOpen: true})

                analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              }}
            >
              {getTitleByLang(element.translations, this.state.language, element.title)}
              <Popover open={this.state[element.id]} anchorEl={this[element.id]} transition disablePortal
                anchorReference="anchorPosition"
                anchorPosition={{ top: 100, left: 300 }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={()=> this.setState({[element.id]: false, isAnyMenuOpen: false})}
              >
                  <MenuList style={{backgroundColor: "var(--secondary-color, white)"}}>
                    {this.state.navbarElements.filter(subElement => !!subElement.parent && subElement.parent == element.id).map(subElement =>
                      <MenuItem style={{cursor: "default", minWidth: 100}}>
                        {this.makeDropDownButton(subElement)}
                      </MenuItem>
                    )}
                  </MenuList>

              </Popover>
            </Link>
            )
        } else {
            return (
              <div 
                style={{display: "inline-block"}}
                aria-owns={this.state[element.id] ? "menu-list-grow" : null}
                aria-haspopup="true"
                buttonRef={node => {
                  this[element.id] = node;
                }}
              >
                <Link
                  className={`${divClass} ${currentSelected == element.id ? 'selected-navbar-button ': ""}`}
                  to="#"
                  style={divClass != "channel-navbar-link" ? {
                    backgroundColor: element.background_color,
                    color: element.font_color, borderColor: element.font_color, 
                    ...(currentSelected == element.id ? {padding: "20px 25px 15px", borderBottom: `5px solid ${element.font_color}`} : {})
                  } : {}}
                  onClick={e =>{
                    this.setState({[element.id]: true})
                    analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
                  }}
                >
                  {getTitleByLang(element.translations, this.state.language, element.title)}
                </Link>
                <Popper open={this.state[element.id]} anchorEl={this[element.id]} transition disablePortal>
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={()=> this.setState({[element.id]: false})}>
                          <MenuList style={{backgroundColor: "var(--secondary-color, white)"}}>
                            {this.state.navbarElements.filter(subElement => !!subElement.parent && subElement.parent == element.id).map(subElement =>
                              <MenuItem style={{cursor: "default", minWidth: 100}}>
                                {this.makeDropDownButton(subElement)}
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )
        }
    }
  }

  makeDropDownButton = (element, className = "") => {
    switch (element.data_source) {
      case "link":
        const domain = window.location.hostname.replace('www.','');
        let isSameDomain = element.metadata.link.startsWith(`${window.location.protocol}//${window.location.hostname}`) || element.metadata.link.startsWith(`${window.location.protocol}//${domain}`);

        return (
          <Link
            className={`${className} drop-down-link `}
            to={isSameDomain ? channelPathGenerator(element.metadata.link.split('//')[1].split('/').slice(1).join('/')) : addHttpsToUrl(element.metadata.link)}
            rel="nofollow"
            onClick={e => {
              e.preventDefault()
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.setState({[element.parent]: false})

              if(className !== "") this.setState({sidebar: false})
            
              if (!element.new_tab) {
                if (!isSameDomain) {
                  window.location.href = addHttpsToUrl(element.metadata.link)
                }else {
                  browserHistory.push(channelPathGenerator(element.metadata.link.split('//')[1].split('/').slice(1).join('/')))
                }
              } else {
                window.open(addHttpsToUrl(element.metadata.link), '_blank').focus();
              }
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "section":
        return(
          <Link
            className={`${className} drop-down-link `}
            to={rootPath(`/home/${element.metadata.target_section_id}-${slugify(element.metadata.target_section_name).replace(/-/g, '_')}`)}
            onClick={e => {
              e.preventDefault()
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              if(className !== "") this.setState({sidebar: false})
              this.setState({[element.parent]: false})

              browserHistory.push(rootPath(`/home/${element.metadata.target_section_id}-${slugify(element.metadata.target_section_name).replace(/-/g, '_')}`))
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "contact_form":
        return (
          <Link
            className={`${className} drop-down-link `}
            // to={channelPathGenerator(`contacto`)}
            to={"#"}
            onClick={e => {
              this.setState({[element.parent]: false, showContactDialog: true})
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "home":
        return (
          <Link
            className={`${className} drop-down-link `}
            to={rootPath()}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              if(getActiveSection()) {
                e.preventDefault();
                browserHistory.push(rootPath())
              }
              this.setState({[element.parent]: false})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "content_creator":
        return (
          <Link
            className={`${className} drop-down-link `}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            onClick={e => {
              this.setState({[element.parent]: false})
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "catalog" :
        return (
          <Link
            className={`${className} drop-down-link `}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.setState({[element.parent]: false})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "video_content_creation":
        return (
          <Link
            className={`${className} drop-down-link `}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.setState({[element.parent]: false})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "single_content_creator":
        return(
          <Link
            className={`${className} drop-down-link `}
            to={channelPathGenerator(`contenido/${element.content_creator_id}-${slugify(element.content_creator_name)}?origin=${encodeURIComponent(getMainTranslation(element, "title", true))}`)}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.setState({[element.parent]: false})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "smart_search":
        return (
          <Link
            className={`${className} drop-down-link `}
            to={channelPathGenerator(`busqueda-inteligente`)}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.setState({[element.parent]: false})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
      case "channel_playlist":
        return (
          <Link
            className={`${className} drop-down-link `}
            to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
              this.setState({[element.parent]: false})
              if(className !== "") this.setState({sidebar: false})
            
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>)
        break;
    }
  }

  changeLang = (lang) => navigateToCurrentLangRoute(lang);


  nextLang = () => {
    let current = localization.getLanguage();
    let currentIndex = this.state.availableLanguages.indexOf(current);
    let lang = ((currentIndex !== -1) && (currentIndex < (this.state.availableLanguages.length - 1))) ? this.state.availableLanguages[currentIndex+1] : this.state.availableLanguages[0];
    return this.changeLang(lang);
  }

  addFontSpecificClass = (font) => {
    switch (font) {
      case 'roboto-font':
        return "livestream-link-txt-roboto"
      case 'avenir-font':
        return "livestream-link-txt-avenir"
      case 'gotham-font':
        return "livestream-link-txt-gotham"
    }
  }

  channelLogo = () => {
    const channel = this.state.channel;
    return (<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center"}}>
      <Link
        className="channel-navbar-image-container-link"
        style={{width: get(getStoredChannel(), "preferences.navbar.center_mode", false) ? 200: "auto"}}
        onClick={(e) => {
          if(getActiveSection()) {
            e.preventDefault();
            browserHistory.push(rootPath())
          }
        }}
        to={rootPath()}>
        <img className="channel-navbar-image"
             src={(channel && channel.image) ?
               makeFitImageUrl(channel, 250, 40, '', 'png')
               :
               (channel ?
                 makeFitImageUrl(channel.client, 250, 40, '', 'png')
                 :Logo)}
             alt={`Logo - ${(channel && channel.image) ? channel.name : channel.client.name}`} />
        {(channel && !channel.image && !(channel.client && channel.client.image)) &&
          <>
            <div className="channel-navbar-client-name-pipe">|</div>
            <div className="channel-navbar-client-name">{channel.name}</div>
          </>
        }
        
        {/* {(channel.demo || (get(getStoredUser(), "brand", null) && getChannelCanLicense())) &&
          <div className="channel-navbar-demo" style={{textDecoration: "uppercase"}}>
            {!!(get(getStoredUser(), "brand", null) && getChannelCanLicense()) ? getStoredUser().brand.name :localization.get('demo')}
          </div>
        } */}
      </Link>
      {window.innerWidth < 600 && getStoredChannel() && getStoredChannel().show_date &&
        <span
          className={this.addFontSpecificClass(getStoredChannel().preferences.main_font)}
          style={{marginLeft: 0, opacity: "0.5", color: "color: var(--navbar-text-color, black)", fontSize: 12, borderTop: "1px solid"}}>
          {moment().format('LL')}
        </span>
      }
    </div>)
  }

  renderContactDialog = () =>
    <Dialog open={this.state.showContactDialog} onBackdropClick={() => this.setState({showContactDialog: false})} maxWidth="false">
      <div style={{width: !checkIfMobile() ? 700 : "75vw"}}>
        <Contact
          modal={true}
          onSubmit={(name, email, description) => request.channel.contact(name, email, description)}
          afterSubmit={() => this.setState({showContactDialog: false})}/>
      </div>
    </Dialog>

  makeNavbarAcctions = () => {
    const { open, channel } = this.state;

    let currentUser = getStoredUser();
    let channelCreator = this.state.channel && currentUser && currentUser.contentCreators.find(c => c.channel_id == this.state.channel.id);
    let isInvisible = channelCreator && !channelCreator.active;
    let showLiveStreamButton = this.state.livestreamData;
    let showLiveRadioButton = ((getStoredChannel().radio_enabled == 1) && getStoredChannel().radio_link);
    let centerMode = get(getStoredChannel(), "preferences.navbar.center_mode", false);

    return (
      <div className="channel-navbar-user-container">
        {this.state.availableLanguages.length > 1 && window.innerWidth > 1050 &&
          <>
            {this.state.availableLanguages.length === 2 ?
              <IconButton
                className="channel-navbar-user-button"
                onClick={() => this.nextLang()}
                style={{width: 30, height: 30, color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}
                rel="nofollow noindex"
              >
                <span style={{
                  color: "color: var(--navbar-text-color, black)",
                  fontSize: 18
                }}>{localization.getLanguage()}</span>
              </IconButton>
              :
              <>
                <IconButton
                  className="channel-navbar-user-button"
                  buttonRef={node => {
                    this.anchorEl = node;
                  }}
                  aria-owns={this.state.openLangDropdown ? "menu-list-grow" : null}
                  aria-haspopup="true"
                  onClick={this.handleToggleLangDropdown}
                  style={{marginLeft: 10, width: 30, height: 30, color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}
                  rel="nofollow noindex"
                >
                  <span style={{
                    color: "color: var(--navbar-text-color, black)",
                    fontSize: 18
                  }}>{localization.getLanguage()}</span>
                </IconButton>
                <Popper open={this.state.openLangDropdown} anchorEl={this.anchorEl} transition disablePortal>
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => this.setState({ openLangDropdown: !this.state.openLangDropdown })}>
                          <MenuList style={{backgroundColor: "var(--secondary-color, white)"}}>
                            {this.state.availableLanguages.map(lang =>
                              <MenuItem onClick={() => this.changeLang(lang)}>
                                <div style={{color: "var(--navbar-text-color, black)"}}>{localization.get(`language.${lang}.original`)}</div>
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            }
          </>
        }

        {!!getStoredUser() && window.innerWidth > 1050 ?
          <div div style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '0 10px 0'}}>
            <IconButton
              buttonRef={node => {
                this.anchorEl = node;
              }}
              aria-owns={open ? "menu-list-grow" : null}
              aria-haspopup="true"
              onClick={this.handleToggle}
              style={{
                color: 'inherit',
                width: window.innerWidth < 600 ? 20 : 48
              }}
            >
              <div
                className="channel-navbar-user-button-image"
                style={{backgroundImage: `url(${makeImageUrlWithSize(getStoredUser(), 'xs')}), url(${DefaultAvatar})`}}
              />
            </IconButton>
            {this.state.channel && this.state.channel.community && !channelCreator &&
              <Link
                className="channel-navbar-invisible-creator-btn"
                style={{backgroundColor: 'green'}}
                to={channelPathGenerator(`profile`)}
              >{localization.get("navbar.profile.create_button")}</Link>
            }
            {this.state.channel && this.state.channel.community && isInvisible &&
              <Link
                className="channel-navbar-invisible-creator-btn"
                to={channelPathGenerator(`profile`)}
              >{localization.get("navbar.profile.invisible_button")}</Link>
            }
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList style={{backgroundColor: "var(--secondary-color, white)"}}>
                        <MenuItem disabled style={{color: "var(--navbar-text-color, black)", cursor: "default"}} onClick={this.handleClose}>
                          {getStoredUser().name}
                        </MenuItem>
                        {getStoredUser() && (userisMultiClient() || userIsSysAdmin() || userIsSupervisor() || userIsContentSupervisor()) &&
                          <Link
                            style={{color: "var(--navbar-text-color, black)"}}
                            to={'/panel'}
                          >
                            <MenuItem onClick={this.handleClose} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get('navbar.dashboard')}
                            </MenuItem>
                          </Link>
                        }
                        {this.state.channel && this.state.channel.login_type !== "gcba" &&
                            <MenuItem onClick={() => {
                              this.setState({openEditDialog: true})
                              }} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get("navbar.my_account")}
                            </MenuItem>
                        }
                         {this.state.channel && this.state.channel.login_type == "MiBA" &&
                          <Link
                            style={{color: "var(--navbar-text-color, black)"}}
                            to={channelPathGenerator(`mi_perfil`)}
                          >
                            <MenuItem onClick={this.handleClose} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get("navbar.selfmanagement")}
                            </MenuItem>
                          </Link>
                        }
                        {this.state.channel && this.state.channel.paymentGateways && this.state.channel.paymentGateways.length > 0 &&
                          <Link
                            style={{color: "var(--navbar-text-color, black)"}}
                            to={channelPathGenerator(`mis-compras`)}
                          >
                            <MenuItem onClick={this.handleClose} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get("title.my_purchases")}
                            </MenuItem>
                          </Link>
                        }
                        {this.state.channel && this.state.channel.community &&
                          <Link
                            style={{color: "var(--navbar-text-color, black)"}}
                            to={channelPathGenerator(`profile`)}
                          >
                            <MenuItem onClick={this.handleClose} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get("navbar.my_profile")}
                            </MenuItem>
                          </Link>
                        }
                        {this.state.channel && (this.state.channel.use_tools || this.state.channel.use_playlists) && getStoredUser() &&
                          <Link
                            style={{color: "var(--navbar-text-color, black)"}}
                            to={channelPathGenerator(`playlists`)}
                          >
                            <MenuItem onClick={this.handleClose} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get("playlists.own")}
                            </MenuItem>
                          </Link>
                        }
                        {this.state.channel && getChannelCanLicense() && getStoredUser() &&
                          <Link
                            style={{color: "var(--navbar-text-color, black)"}}
                            to={channelPathGenerator(`account/licences`)}
                          >
                            <MenuItem onClick={this.handleClose} style={{color: "var(--navbar-text-color, black)"}}>
                              {localization.get("licenses")}
                            </MenuItem>
                          </Link>
                        }
                        <MenuItem onClick={() => request.authentication.logout()}><div style={{color: "var(--navbar-text-color, black)"}}>{localization.get("navbar.logout")}</div></MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          : null
        }
        {this.state.channel && this.state.channel.community && !channelCreator && !getStoredUser() && window.innerWidth > 1050 &&
            <Link
              className="channel-navbar-invisible-creator-btn"
              style={{backgroundColor: 'green'}}
              to={channelPathGenerator(`profile`)}
            >
              {localization.get("navbar.profile.create_button")}
            </Link>
        }
        {window.innerWidth > 1050 && getStoredChannel().show_login_button && !getStoredUser() &&
          <Link
            to={channelPathGenerator(`login`)}
            className={"channel-navbar-link"}
            style={{position: "relative", float: "right", margin: "0px!important", marginRight: 10}}
          >
            <p>{getStoredChannel() ? getStoredChannel().login : "Login"}</p>
          </Link>
        }
        { (getStoredUser() || getStoredChannel().show_login_button) &&
          <Link
            to={'#'}
            onClick={() => {
              this.validateLoginRedirect(channelPathGenerator('mi-lista'))
            }}
          >
            <IconButton
              className={"channel-navbar-fav-button"}
              style={{color: 'inherit', width: window.innerWidth < 600 ? 20 : 48}}
            >
              <Tooltip placement="bottom" title={localization.get("favorite")}>
                <StarBorder style={{color: "var(--navbar-text-color, black)"}} />
              </Tooltip>
            </IconButton>
          </Link>
        }
        {channel.channelContentCreatorTypes.filter(type => type.show_on_global_search || type.show_on_smart_search).length > 0 &&
          <IconButton
            onClick={() => this.toggleSearch(true)}
            className={"channel-navbar-search-button"}
            style={{
              color: 'inherit',
              width: window.innerWidth < 600 ? 20 : 48
            }}
          >
            <Tooltip placement="bottom" title={localization.get("search")}>
              <Search style={{color: "var(--navbar-text-color, black)"}}/>
            </Tooltip>
          </IconButton>
        }
        <div className={"channel-navbar-double-live-button"}>
          {window.innerWidth > 650 ?
            <>
              {showLiveStreamButton &&
                <Link
                  id="livestream-link"
                  className={`${getStoredChannel().preferences.live_button.shape === 'square' ? 'livestream-link livestream-link-square' : 'livestream-link '}`}
                  to={channelPathGenerator(`vivos`)}
                  style={{backgroundColor: getStoredChannel().preferences.live_button.color, width: this.state.livestreamData.fake ? "110px" : "105px"}}
                  onMouseOut={() => document.getElementById('livestream-link').blur()}
                >
                  {getStoredChannel().preferences.live_button.icon === "show" &&
                  <PlayArrow
                    style={{color: getStoredChannel().preferences.live_button.color}}
                  />
                  }
                  <span
                    className={this.addFontSpecificClass(getStoredChannel().preferences.main_font)}
                    style={getStoredChannel().preferences.live_button.icon === "show" ?
                      {marginLeft: 24, top: 5, position: "absolute", left:this.state.livestreamData.fake ? 8 : 2, fontSize: this.state.livestreamData.fake ? 10 : 18, width: 72} : 
                      {}
                    }
                  >
                    {get(getStoredChannel(), "radio_text", localization.get(this.state.livestreamData.fake ? 'livestream.premiere' : 'on_live'))}</span>
                </Link>
              }
              {showLiveRadioButton &&
                <RadioPlayer
                  url={getStoredChannel().radio_link}
                  fontClass={this.addFontSpecificClass(getStoredChannel().preferences.main_font)}
                  preferences={getStoredChannel().preferences && getStoredChannel().preferences.radio_button}
                />
              }
            </>
          :
            <>
              {showLiveStreamButton &&
                <Link
                  id="livestream-link"
                  className={`${getStoredChannel().preferences.live_button.shape === 'square' ? 'livestream-link livestream-link-square' : 'livestream-link '}`}
                  to={channelPathGenerator(`vivos`)}
                  style={{margin: "5px 5px 5px 0px", width: "30px", height: "30px", backgroundColor: getStoredChannel().preferences.live_button.color}}
                  onMouseOut={() => document.getElementById('livestream-link').blur()}
                >
                  <PlayArrow
                    style={{background: getStoredChannel().preferences.live_button.color, color: 'white'}}
                  />
                </Link>
              }
              {showLiveRadioButton &&
                <RadioPlayer
                  url={getStoredChannel().radio_link}
                  fontClass={this.addFontSpecificClass(getStoredChannel().preferences.main_font)}
                  iconOnly
                  preferences={getStoredChannel().preferences && getStoredChannel().preferences.radio_button}
                />
              }
            </>
          }
          {!showLiveStreamButton && !showLiveRadioButton && !centerMode && (window.innerWidth > 600) && getStoredChannel() && getStoredChannel().show_date &&
            <span
              className={this.addFontSpecificClass(getStoredChannel().preferences.main_font)}
              style={{
                marginRight: 15,
                opacity: "0.5",
                color: "color: var(--navbar-text-color, black)",
                fontSize: "1em"
            }}>{moment().format('LL')}</span>
          }
        </div>
      </div>
    )
  }

  makeMobileButtons = () => {
    const { navbarElements, subNavbarElements } = this.state;
    let centerMode = get(getStoredChannel(), "preferences.navbar.center_mode", false);

    return (
      navbarElements.filter(element => !element.parent).length > 0 &&
        <div className="channel-mobile-navbar" style={{top: centerMode ? 100: 75, backgroundColor: "var(--secondary-color)", overflow: this.state.isAnyMenuOpen ? "hidden" : "auto"}}>
            {navbarElements.filter(element => !element.parent).map(element =>
              this.makeNavbarButton(element, this.state.selectedElement,  "channel-mobile-navbar-links")
            )}
        </div>
    )
  }

  render = () => {
    const { client, sidebar, search, showSearchItems, channel, navbarElements, subNavbarElement, subNavbarElements } = this.state;

    let mobile = this.state.width <= 600;
    let currentUser = getStoredUser();
    let channelCreator = this.state.channel && currentUser && currentUser.contentCreators.find(c => c.channel_id == this.state.channel.id);
    let centerMode = get(getStoredChannel(), "preferences.navbar.center_mode", false);
    const subNavbarElementLength = this.state.width > 600 ? this.state.subNavbarElements.length : 3;

    return (
      <div>

        <Dialog open={this.state.openEditDialog} fullWidth={window.innerWidth < 600}>
          <ProfileForm onClose={() => this.setState({openEditDialog: false})}/>
        </Dialog>
        {this.renderContactDialog()}
        <ContentCreationPlayer
          show={this.state.showContentCreationDialog}
          currentContentCreation={this.state.currentPlayingContent}
          handleClose={() => this.setState({showContentCreationDialog: false,currentPlayingContent: {}})}
          noAuthorLink={userHasRestrictedAccess()}
        />
        { !this.props.transparentMode ?
          <div style={{display: "flex", flexDirection: "column"}}>
            <nav className="channel-navbar" style={{height: centerMode ? 100: 75, justifyContent: "space-between"}}>
              <div className="channel-navbar-image-container" style={{left: (this.state.width > 1200) && "66px"}}>
                {(this.state.width < 1200 || (get(getStoredChannel(), "preferences.navbar.show_menu", false) && getUseTools() || !!getStoredChannel().use_sidebar_desktop)) &&
                  <div className="channel-menu-container">
                    <IconButton onClick={() => this.setState({sidebar: true})} className="channel-navbar-menu-button">
                      <MenuIcon style={{color: "var(--navbar-text-color, black)"}} />
                    </IconButton>
                  </div>
                }
                {!centerMode ? this.channelLogo() :
                  (window.innerWidth > 600 && getStoredChannel().show_date) ?
                  <span
                  className={this.addFontSpecificClass(getStoredChannel().preferences.main_font)}
                  style={{
                    marginLeft: 35,
                    opacity: "0.5",
                    color: "color: var(--navbar-text-color, black)",
                    fontSize: "1em"}}
                  >{moment().format('LL')}</span> : null
                }
              </div>
              <div className={"navbar-logo-with-date"}>
                {centerMode && this.channelLogo()}
                {!this.isProfileUrl() && navbarElements.filter(element => !element.parent).length > 0 &&
                  <div className="channel-navbar-links" >
                    {navbarElements.filter(element => !element.parent).map(element =>
                      this.makeNavbarButton(element, this.state.selectedElement)
                    )}

                    {!!(getStoredChannel().use_brand_button && get(getStoredUser(), "brand.link")) && 
                      <Link
                        className={`channel-navbar-link`}
                        to={addHttpsToUrl(get(getStoredUser(), "brand.link"))}
                        rel="nofollow"
                        target="_blank"
                      >
                        {get(getStoredUser(), "brand.button_name")}
                      </Link>
                    }
                  </div>
                }
              </div>
              {/*Profile*/}
              {!this.isProfileUrl() ?
                  this.makeNavbarAcctions()
                :
                <div className="navbar-close-profile-container">
                  <Link
                    to={channelPathGenerator("")}
                    class={"navbar-close-profile-link"}
                  >
                    {localization.get("navbar.close_profile")}
                  </Link>

                  <Link to={channelPathGenerator("")} class={"navbar-close-profile-button"}>
                    <IconButton>
                      <Close style={{fontSize: '20px', color: "var(--navbar-text-color, black)"}}/>
                    </IconButton>
                  </Link>
                </div>
              }
              
            </nav>
            {subNavbarElement && subNavbarElements.length > 0 &&
              <div className="channel-subnavbar" style={{top: centerMode ? 100: 75,}}>
                {subNavbarElements.slice(0, subNavbarElementLength).map(element =>
                  this.makeNavbarButton(element, this.state.selectedElement)
                )}
              </div>
            }

            {(mobile && !!channel.navbar_tabs) && this.makeMobileButtons()}
            
            <div className="channel-navbar-search-container" style={{right: catalogClientView() && (mobile ? "-70px" : "-40px")}}>

            </div>
          </div>
          :
          <nav className="channel-navbar transparent">
            <div className="channel-navbar-image-container" style={{left: catalogClientView() && "10%"}}>
              <Link className="channel-navbar-image-container-link" to={channelPathGenerator(``)}>
                <img className="channel-navbar-image" style={{filter:  "grayscale(1)", marginTop: (!client || !(client.use_logo && client.image)) && '5px'}} src={(client && client.use_logo && client.image) ? makeFitImageUrl(this.props.client, 250,35, '', 'png') : (client && client.managedBy && client.managedBy.image) ? makeFitImageUrl(client.managedBy, 250,35, '', 'png') : LogoWhite} alt="Logo Feater" />
                {(client && !client.use_logo) &&
                <div className="channel-navbar-client-name" style={{color: "white"}}>
                  <div className="channel-navbar-client-name-pipe" style={{color: "white"}}>|</div>
                  {client.name}
                </div>
                }
              </Link>
            </div>
          </nav>
        }

        <ClickAwayListener onClickAway={() => this.toggleSearch(false)}>
          <div>
            <div style={{ height: centerMode ? 100 : 75, display: search ? "flex": "none"}} className="search-bar">
              {window.innerWidth > 1050 &&
              <img className="channel-navbar-image-search-bar"
                   style={{display: !search ? "none" : "block", cursor: "pointer"}}
                   onClick={() => {
                     this.toggleSearch(false)
                     browserHistory.push(channelPathGenerator(``))
                   }}
                   src={(channel && channel.image) ?
                     makeFitImageUrl(channel, 250, 40, '', 'png')
                     :
                     (channel ?
                       makeFitImageUrl(channel.client, 250, 40, '', 'png')
                       : Logo)}
                   alt={`Logo ${channel.name}`}/>
              }
              {this.state.channel.show_advanced_search === "search" && showSearchItems &&  window.innerWidth > 850 && window.innerWidth < 1300 &&
                <Link
                  to={channelPathGenerator(`busqueda-inteligente`)}
                  class={"smart-search-link"}
                  onClick={e => {
                    e.preventDefault();
                    this.toggleSearch(false)
                    browserHistory.push(channelPathGenerator(`busqueda-inteligente`))
                  }}
                >
                  {localization.get("go_to.smart_search")}
                </Link>
              }
              <div style={{ display: showSearchItems ? "inline-block" : "none" }} className="searchbar-input-container">
                <TextField
                  inputProps={{
                    ref: input => {
                      this.searchInput = input;
                    },
                    spellCheck: false,
                    style:{color: "var(--navbar-text-color, black)", borderBottom: "2px solid var(--navbar-text-color, black)"}
                  }}
                  InputProps={{disableUnderline: true}}
                  autoComplete={false}
                  className="searchbar-input"
                  onKeyPress={this.handleSearchInputKeyPress}
                  placeholder={this.state.channel.global_search_text || localization.get("searchbar.input")}
                  value={this.state.searchInput}
                  onChange={this.handleSearchInputOnChange}
                  style={{color: "var(--navbar-text-color, black)"}}
                />
              </div>
              <div
                // style={{ display: showSearchItems ? "block" : "none" }}
                className="channel-navbar-close-search-button-container">
                {this.state.channel.show_advanced_search === "search" && showSearchItems && window.innerWidth > 1300 &&
                  <Link
                    to={channelPathGenerator(`busqueda-inteligente`)}
                    class={"smart-search-link"}
                    onClick={e => {
                      e.preventDefault();
                      this.toggleSearch(false)
                      browserHistory.push(channelPathGenerator(`busqueda-inteligente`))
                    }}
                  >
                    {localization.get("go_to.smart_search")}
                  </Link>
                }
                <IconButton onClick={() => this.toggleSearch(false)} className="channel-navbar-menu-button">
                  <Close style={{color: "var(--navbar-text-color, black)"}} />
                </IconButton>
              </div>
            </div>

            <div id="searchbar-results-container" className="searchbar-results-container" style={{backgroundColor: "var(--secondary-color, white)"}}>
              <div className="searchbar-results-items-container">
                {this.state.channel.show_advanced_search === "search" && showSearchItems && window.innerWidth < 850 && !(this.state.searchResultComponents && this.state.searchResultComponents.length > 0 && !this.state.isEmpty) &&
                  <>
                    <Link
                      onClick={() => this.toggleSearch(false)}
                      to={channelPathGenerator(`busqueda-inteligente`)}
                      class={"smart-search-link"}
                      style={{paddingBottom: window.innerWidth > 600 ? 60 :35}}
                    >
                      {localization.get("go_to.smart_search")}
                    </Link>
                  </>
                }
                {this.state.searchResultComponents}
              </div>
            </div>
          </div>
        </ClickAwayListener>
        <MobileSidebar 
          open={sidebar}
          channel={this.state.channel}
          navbarElements={navbarElements}
          onClose={() => this.setState({sidebar: false})}
          channelCreator={channelCreator}
          availableLanguages={this.state.availableLanguages}
          language={this.state.language}
          livestream={this.state.livestreamData}
          makeButton={this.makeNavbarButton}
          makeDropdownButton={this.makeDropDownButton}
          getMainTranslation={getMainTranslation}
          changeLang={this.changeLang}
          openProfileDialog={() => this.setState({openEditDialog: !this.state.openEditDialog})}
        />
        
      </div>
    );
  }
}

channelNavbar.defaultProps = {
  transparentMode: false
};

export default channelNavbar;
