import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import { getStoredChannel, getMainTranslation, getActiveLivestreams, slugify, channelPathGenerator } from '../../../api-client/core/authentication/utils';
import EventManager, {events} from "../../../utils/EventManager";


class HomeElementEventGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupedEvents: {},
      selectedDate: null,
      visibleDates: []
    };
  }

  componentDidMount() {
    moment.locale('es');
    this.groupEventsByDay();
  }

  groupEventsByDay = () => {
    const items = this.props.homeElement.items.data;
    const grouped = {};
    
    items.forEach(event => {
    
      const date = moment(event.date).format('YYYY-MM-DD');
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push({
        ...event,
        time: moment(event.date).format('HH:mm:ss'),
        duration: event.duration || 0
      });
    });

    Object.keys(grouped).forEach(date => {
      grouped[date].sort((a, b) => moment(a.time, 'HH:mm:ss').diff(moment(b.time, 'HH:mm:ss')));
    });

    const dates = Object.keys(grouped).sort();
    
    this.setState({ 
      groupedEvents: grouped,
      selectedDate: dates[0],
      visibleDates: dates
    });
  }

  renderDateSelector = () => {
    const { visibleDates, selectedDate } = this.state;
    const { preferences } = this.props.homeElement;
    const { active_color, hover_text_color, hover_background_color } = preferences;

    return (
      <div className="date-selector" style={{...styles.dateSelector}}>
        {visibleDates.map(date => (
          <div 
            key={date}
            onClick={() => this.setState({ selectedDate: date })}
            style={{
              ...styles.dateButton,
              backgroundColor: selectedDate == date ? active_color : hover_background_color,
              color: selectedDate == date ? hover_text_color : "var(--title-normal)"
            }}
          >
            {moment(date).format('dddd D')}
          </div>
        ))}
      </div>
    );
  }

  openContentCreatorModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
      id: this.props.data.id,
      dataType: "contentCreator",
      dataName: this.props.data.name,
      onTagChange: this.props.handleNewSearch || false,
      origin: this.props.home ? (this.props.homeElement ? getMainTranslation(this.props.homeElement, "title", true): "") : this.props.search ? 'Search' : this.props.realated ? 'Related' : '',
    });
  }

  renderEvents = () => {
    const { groupedEvents, selectedDate } = this.state;
    const events = groupedEvents[selectedDate] || [];
    const now = moment();
    const linkTo = this.props.linkTo;
    const { preferences } = this.props.homeElement;
    const { active_color, hover_text_color, hover_background_color } = preferences;

    return (
      <div className="events-list" style={styles.eventsList}>
        <style>
          {`
            .event-row {
              display: flex;
              padding: 15px;
              margin: 0 15px;
              color: var(--main-font-color)!important;
              border-bottom: 1px solid var(--title-normal);
              transition: all 0.3s ease;
              border-radius: 4px;
              cursor: pointer;
              position: relative;x
              border-left: 4px solid transparent;
              min-height: 105px;
            }
            
            .event-row:hover {
              background-color: ${hover_background_color}!important;
              transform: translateX(8px);
              border-left: 4px solid var(--main-font-color)!important;
              padding-left: 26px;
              margin-left: 0;
              color: ${hover_text_color}!important;
            }

            .event-row.active {
              background-color: ${hover_background_color}!important;
              border-left: 4px solid ${active_color}!important;
              color: ${hover_text_color}!important;
              margin-left: 0;
              padding-left: 26px;
              transform: none;

            }

            .event-row .event-synopsis {
              font-size: 14px;
              color: var(--main-font-color)!important;
              line-height: 1.4;
            }
              
            .event-row:hover .event-synopsis {
                color: ${hover_text_color}!important;
            }
            .event-row.active .event-synopsis {
                color: ${hover_text_color}!important;
            }
            .event-row .event-duration {
                color: var(--main-font-color)!important;
            }
            .event-row:hover .event-duration {
                color: ${hover_text_color}!important;
            }
            .event-row.active .event-duration {
                color: ${hover_text_color}!important;
            }
            

            .event-row.active:hover {
              background-color: ${hover_background_color}!important
              transform: scale(1.01);
              box-shadow: 0 4px 8px ${hover_background_color}!important;
              color: ${hover_text_color};
            }            
          `}
        </style>
        {events.map((event, index) => {
          const durationInMinutes = (event.duration || 0) * 60;
          const startTime = moment.utc(`${selectedDate} ${event.time}`).local();
          const endTime = moment(startTime).add(durationInMinutes, 'minutes');
          const isCurrentEvent = now.isBetween(startTime, endTime, null, '[]');
          const livestreamLink = getActiveLivestreams().length > 0 ? channelPathGenerator(`vivo/${getActiveLivestreams()[0].id}-${slugify(getActiveLivestreams()[0].name)}`) : null;

          return (
            <Link 
              key={`${event.id}-${index}`} 
              className={`event-row ${isCurrentEvent ? 'active' : ''}`}
              to={
                linkTo == "default" ? channelPathGenerator(`contenido/${event.id}-${slugify(event.name)}`) :
                linkTo == "livestream" ? livestreamLink :
                `#`
              }

              onClick={(e) => {
                if (getStoredChannel().use_canonicals != "1" && linkTo == "default"){
                    e.preventDefault();
                    this.openContentCreatorModal();
                }else if (!isCurrentEvent){
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }}
            >
              <div style={styles.eventTime}>
                {/* {moment(event.time, 'HH:mm:ss').format('HH:mm')}hs */}
                {startTime.format('HH:mm')}hs
                {event.duration > 0 && (
                  <span className="event-duration">
                    - {startTime.add(durationInMinutes, 'minutes').format('HH:mm')}hs
                  </span>
                )}
                {livestreamLink && isCurrentEvent && (
                    <span style={{...styles.liveTag, backgroundColor: active_color}}>EN VIVO</span>
                )}

              </div>
              <div style={styles.eventContent}>
                <div style={styles.eventTitle}>
                  {event.title || event.name}
                </div>

                {event.synopsis && (
                  <div className="event-synopsis">
                    {event.synopsis}
                  </div>
                )}

              </div>
            </Link>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <h2 style={styles.title}>Grilla de horarios</h2>
        {this.renderDateSelector()}
        {this.renderEvents()}
      </div>
    );
  }
}

const styles = {
  container: {
    width: '100%',
    padding: '20px'
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px'
  },
  dateSelector: {
    display: 'flex',
    overflowX: 'auto',
    marginBottom: '20px',
    gap: '10px'
  },
  dateButton: {
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '4px',
    textTransform: 'capitalize'
  },
  eventsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  
  eventTime: {
    width: '120px',
    flexShrink: 0,
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },
  eventDuration: {
    fontSize: '14px',
  },
  eventContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  eventTitle: {
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  liveTag: {
    padding: '2px 8px',
    borderRadius: '4px',
    fontSize: '12px',
    position: 'absolute',
    bottom: 15
  }


};

export default HomeElementEventGrid;
