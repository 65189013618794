import React from "react";
import EventManager, {events} from "../../../utils/EventManager";
import {Tooltip} from "@material-ui/core";
import HomeElementItemState from "./HomeElementItemStateX4";
import {makeImageCarousel} from "./HomeElementCarouselItem";
import {
  channelCanAddToFavorites, channelPathGenerator, GAevent, generateLink,
  getActiveChannel,
  getActiveClient, getUseTools, getStoredChannel, getStoredUser,
  homePathGenerator, slugify, translateTypes, getMainTranslation
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import {Link} from "react-router-dom";
import styleSquare from "./Styles/Carousel/newMusicalAlbums.module.css"
import styleRectangle from "./Styles/Carousel/rectangleOnlyImage.module.css"
import style16_9 from "./Styles/Carousel/mostviewed.module.css"
import style16_9Inverted from "./Styles/Carousel/mostviewedInverted.module.css"
import styleRoundX6 from "./Styles/Carousel/podcastArt.module.css"
import styleRoundX4 from "./Styles/Carousel/podcast.module.css"
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import {ReactComponent as Heart} from "../../../components/Letflow/SvgIcons/heart.svg"
import {ReactComponent as Share} from "../../../components/Letflow/SvgIcons/share.svg"
import {ReactComponent as Person} from "../../../components/Letflow/SvgIcons/person.svg"
import {ReactComponent as Group} from "../../../components/Letflow/SvgIcons/groups.svg"
import font from "./Styles/Fonts/fonts.module.css";
import {convertFromRaw, EditorState} from "draft-js";
import browserHistory from "../../../utils/browserHistory";
import {makeCustomClick, makeCustomLink} from "./utils";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import {request} from "../../../api-client";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import st from "../HomeNew.module.css";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { YouTubeEmbed } from 'react-social-media-embed';
import {get} from "lodash"
import NoImage from "../../../assets/img/Letflow/no-image.jpg";

class HomeElementCarouselItemOnlyImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeElement: this.props.homeElement,
      showShareDialog: false,
      playlistToShareId: null,
      limitReached: false,
      shareUrl: null
    };
    this.catchedClick = false;
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
    this.checkViewsLimit()
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
  }

  checkViewsLimit = () => {
    if (this.props.data.view_limit > 0 && this.props.data.views >= this.props.data.view_limit) {
      this.setState({limitReached: true})
    }
  }

  addToFavoriteItemDescription = ({id, type}) => {
    this.addToFavorites({id, type})
  }

  removeFromFavoritesItemDescription = ({id, type}) => {
    this.removeFromFavorites({id, type})
  }

  addToFavorites = ({id, type}) => {
    if (this.props.data.id === id && this.props.data.kind === type) {
      let items = this.state.homeElement.items.data.map(item => {
        if (item.id === id) {
          item.favorite = true;
        }
        return item;
      });
      !!getStoredUser() && this.setState({
        homeElement: {...this.state.homeElement, items: {data: items}}
      });

      EventManager.getInstance().dispatch(events.ADD_TO_FAVORITE_BUTTON_PRESS, {id, type});
    }
  };

  removeFromFavorites = ({id, type}) => {
    if (this.props.data.id === id && this.props.data.kind === type) {
      let items = this.state.homeElement.items.data.map(item => {
        if (item.id === id) {
          item.favorite = false;
        }
        return item;
      });

      this.setState({
        homeElement: {...this.state.homeElement, items: {data: items}}
      });

      EventManager.getInstance().dispatch(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, {id, type});
    }
  };

  renderShareDialog = () => {
    if (this.state.shareUrl) {
      return (<ShareOnSocials
        show={this.state.showDialog}
        handleClose={() => this.setState({showDialog: false, shareUrl: null})}
        url={this.state.shareUrl}
        darkMode={true}
      />);
    }
    return (<ShareDialog
      show={this.state.showShareDialog}
      handleClose={() => this.setState({showShareDialog: false})}
      entity={'playlist'}
      id={this.state.playlistToShareId}
    />);
  }

  sharePlaylist = (playlistId) => {
    if (getUseTools()) {
      return request.playlist.shareByChannel(playlistId)
        .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
        .catch(GlobalSnackbar.showGenericError)
    }else {
      request.playlist.share(playlistId, null, getActiveClient())
        .then(response => this.setState({
          showDialog: true, 
          shareUrl: `${window.location.protocol}//${window.location.hostname}/public/playlists/${response.share_token}`
        }))
        .catch(GlobalSnackbar.showGenericError)
    }
  }

  toggleModalFavorite = (data) => {
    let items = this.state.homeElement.items.data.map(item => {
      if (item.id === data.id) {
        item.favorite = !item.favorite;
      }
      return item;
    });

    this.setState({
      homeElement: { ...this.state.homeElement, items: { data: items } }
    });
  }

  openContentCreatorModal = () => {
    EventManager.getInstance().dispatch(
      events.OPEN_CONTENT_MODAL,
      {
        id: this.props.data.kind === 'video_content_creation' ? this.props.data.content_creator_id : this.props.data.id,
        dataType: "contentCreator",
        dataName: this.props.data.kind === 'video_content_creation' ? '' : this.props.data.name,
        origin: this.props.home ? (this.props.homeElement ? getMainTranslation(this.props.homeElement, "title", true): "") : this.props.search ? 'Search' : this.props.realated ? 'Related' : '',
      });
  }

  onContentCreatorClick = (e, data) => {
    if (getActiveChannel() && getStoredChannel().use_canonicals == "1") {
      return;
    }
    e.preventDefault()
    if (this.state.limitReached) {
      EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
    } else {
      if (e.ctrlKey || (getActiveChannel() && getStoredChannel().use_canonicals == "1")) {
        window.open(getActiveChannel() ? channelPathGenerator(`${translateTypes(data.kind)}/${data.id}-${slugify(data.name || data.title)}`) : homePathGenerator(`${data.kind}/${data.id}`))
      } else {
        this.openContentCreatorModal()
      }
    }
  }

  makeFavoriteButton = (data, favorite, icon) => {
    if (!this.state.limitReached) {
      let onClick = ''
      if (favorite) {
        onClick = (e) => {
          e.stopPropagation()
          const trackingName = getMainTranslation(data, "name")
          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: "Remove"
          });
          this.removeFromFavorites({id: data.id, type: this.props.data.kind})
        }
      } else {
        onClick = (e) => {
          e.preventDefault();
          e.stopPropagation();

          const trackingName = getMainTranslation(data, "name")
          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: "Add"
          });
          // GAevent('Contenido', "Favorite", data.name || data.title)
          this.addToFavorites({id: data.id, type: this.props.data.kind})
        }
      }

      if (!this.props.suggestedCarousel) {
        switch (icon) {
          case 'star':
            return <Star onClick={onClick}/>
          case 'heart':
            return <Heart onClick={onClick}/>
          case 'list':
            return <List onClick={onClick}/>
        }
      }
    }
  }

  makeCarouselStyles = (isImg) => {
    switch (this.props.shape) {
      case 'square':
        return !isImg ? styleSquare : {width: 400, height: 400, fit: false}
      case 'rectangle':
        return !isImg ? styleRectangle: {width: 400, height: 500, fit: false}
      case '16:9':
        return !isImg ? (((this.props.textPosition && this.props.textPosition === 'bottom')) ? style16_9Inverted : style16_9): {width: 600, height: 335, fit: false}
      case 'round':
        if (this.props.columns === 6) {
          return !isImg ? styleRoundX6: {width: 400, height: 400, fit: false}
        } else {
          return !isImg ? styleRoundX4: {width: 400, height: 400, fit: false}
        }
    }
  }

  getBorders = () => {
    let  border = "0px"

    if (this.props.rounded) {
      switch (this.props.shape) {
        case 'square':
          border = "50px"
          break;
        case 'rectangle':
          border = "50px"
          break;
        case '16:9':
          border = "20px"
          break;
        case 'round':
          border = "50%"
          break;
      }
    } else if (this.props.shape === "round") {
      border = "50%"
    }

    return border
  }

  makeContainerTitleStyle = () => {
    let style = {}
    if (getActiveChannel()) {
      switch (this.props.shape) {
        case 'square':
        case 'rectangle':
          style.fontSize = getStoredChannel().preferences.container_title.size;
          break;
        case '16:9':
          style.fontSize = Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 0.7);
          break;
        case 'round':
          if (this.props.columns === 6) {
            style.fontSize = Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 0.7);
            break;
          } else {
            style.fontSize = getStoredChannel().preferences.container_title.size;
            break;
          }
      }

    }
    if (this.props.shape === '16:9' && !this.props.numbered) {
      if (this.props.textPosition && this.props.textPosition === 'bottom') {
        style.bottom = 0;
      } else {
        style.top = 0;
      }
      style.width = '90%'
    }
    return style
  }

  makeContainerDescriptionStyle = () => {
    let style = {whiteSpace: "pre-line"}
    if (getActiveChannel()) {
      switch (this.props.shape) {
        case 'square':
        case 'rectangle':
          style.fontSize = getStoredChannel().preferences.container_description.size;
          break;
        case '16:9':
          style.fontSize = Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 0.7);
          break;
        case 'round':
          if (this.props.columns === 6) {
            style.fontSize = Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 0.7);
            break;
          } else {
            style.fontSize = getStoredChannel().preferences.container_description.size;
            break;
          }
      }

    }
    return style
  }

  makeEditorialDescription = (data) => {
    let description = EditorState.createWithContent(convertFromRaw(JSON.parse(data.shortDescription)))
    return description.getCurrentContent().getPlainText()
  }

  getStoredChannelContainerTitleWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_title) {
      switch(channel.preferences.container_title.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default-text";
  }

  getStoredChannelContainerDescriptionWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_description) {
      switch(channel.preferences.container_description.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default_text";
  }

  render = () => {
    const {data, handleElementClick} = this.props;
    const mustCatchClick = window.innerWidth < 650 && data && data.kind == 'content_creator';
    let type;

    if (data.client_type) {
      type = "speaker";
    } else if (["external", "internal"].includes(data.type)) {
      type = "artist";
    } else if (data.kind === "playlist") {
      type = "playlist";
    } else if (data.kind === "favorites") {
      type = "favorites";
    } else if (data.kind === "recents") {
      type = "recents";
    } else if (data.kind === "savedQuery") {
      type = "savedQuery";
    } else if (data.kind === "album") {
      type = "album";
    } else if (data.kind === "production") {
      type = "production";
    } else {
      type = "other";
    }

    const style = this.makeCarouselStyles(false)
    const imgSize = this.makeCarouselStyles(true)
    const border = this.getBorders();

    if(this.props.data.data_source == 'embed') {
      return (
       <div
           className={`${style['items-content', 'embed-container']}`}
         >
           <YouTubeEmbed
             placeholderImageUrl={makeImageCarousel(data, imgSize)}
             url={get(this.props.data, "extra.link", "")}
             width={"100%"}
             height={300}
           />
       </div>
      );
   }

    return (
      <>
        <Link
          rel={(data.type === 'custom' && data.data_source == 'link') ? "nofollow" : "canonical"}
          to={data.type === 'custom' ? makeCustomLink(data) : generateLink(data.kind, data.id, data.title || data.name, false)}
          className={`${style['items-content']} ${this.props.inverted && style['inverted']} ${this.props.hasMargin && style['has-margin']}`}
          onClick={(e) => {
            if (!this.catchedClick && mustCatchClick) {
              this.catchedClick = true;
              e.preventDefault();
              e.stopPropagation();
              return false;
            }
            if (data.type === 'custom') {
              makeCustomClick(e, data)
              return;
            }else if (!['artist', 'production', 'content_creator', 'playlist'].includes(data.kind)) {
              e.preventDefault()
              handleElementClick()
            } else if (data.kind === 'content_creator') {
              if (this.state.limitReached) {
                e.preventDefault();
                EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
              } else if (!getActiveChannel() || getStoredChannel().use_canonicals != "1"){
                e.preventDefault();
                this.openContentCreatorModal();
              }
            } else if (data.kind === 'playlist') {
              browserHistory.push(getActiveChannel() ? channelPathGenerator(`${data.kind}/${data.id}`) : homePathGenerator(`${data.kind}/${data.id}`));
            }
          }}
          style={{borderRadius: border}}
        >
          {this.state.limitReached &&
            <Tooltip placement={"top"} title={localization.get('content.views_limit_reached')}>
              <Group className={style['limit-reached']}/>
            </Tooltip>
          }
          <div className={style['photo-container']} style={{borderRadius: border}}>
            <div
              className={style['photo']}
              style={{
                backgroundImage: `url(${makeImageCarousel(data, imgSize)}), url(${NoImage})`,
                borderRadius: border,
              }}
            >
              {((type === "playlist" && (!data.created_by_admin || !data.image)) || type === "savedQuery" || type === "production") && (
                <div
                  className={`featured-image-title ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                  style={getActiveChannel() ? {
                    fontSize: window.innerWidth > 650 ? getStoredChannel().preferences.container_title.size : "1.15rem",
                    color: getStoredChannel().preferences.container_title.color,
                    fontFamily: getStoredChannel().preferences.text_font
                  }: {
                    color: data.featuredImage
                      ? data.featuredImage.color
                      : type === 'production' ? "rgb(128, 144, 180)" : "rgb(252, 205, 47)"
                  }}
                >
                  {data.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                </div>
              )}
            </div>
            <HomeElementItemState
              recentlyCreated={data.recentlyCreated}
              recentlyUpdated={data.recentlyUpdated}
              fullOpacity={this.state.over}
            />
          </div>

          {(['album', 'content_creator', 'artist', 'video_content_creation', 'playlist', 'custom'].includes(data.kind) || data.client_type) &&
          <div
            className={style['info']}
            onClick={(e) => {
              if (!this.catchedClick && mustCatchClick) {
                this.catchedClick = true;
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
              if (!['artist','production', 'content_creator', 'playlist', 'custom'].includes(data.kind)) {
                e.preventDefault()
                handleElementClick()
              } else if (data.kind === 'content_creator') {
                this.onContentCreatorClick(e, data)
              }
            }}
          >
            {this.props.shape === '16:9' && (!this.props.numbered || (this.props.textPosition && this.props.textPosition === 'bottom')) && this.props.columns === 4 && (data.additions && data.additions.split(';')[1]) &&
              <div
                className={`${style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              >
                {data.additions.split(';')[1]}
              </div>
            }
            <div
              className={`${style["title"]} ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              style={this.makeContainerTitleStyle()}
            >
              {data.title || data.name}
            </div>
            {this.props.shape === '16:9' && this.props.columns === 4 && data.additions && data.additions !== ";" && !!data.additions.split(';')[0] &&
              <div
                className={`${style["author"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              >
                {data.additions.split(';')[0]}
              </div>
            }
            {data.kind === 'album' || this.props.numbered || this.props.shape === 'rectangle' &&
              <div
                className={`${style["text"]} ${st[this.getStoredChannelContainerDescriptionWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
                style={this.makeContainerDescriptionStyle()}
                dangerouslySetInnerHTML={{ __html: data.artist ? data.artist.name :
                    (data.shortDescription || data.synopsis) ?
                      (data.kind === "content_creator" ? (data.synopsis || "") :
                        data.shortDescription && data.shortDescription.replace(/<[^>]*>|&nbsp;/g, ' '))
                      : data.subtitle
                }}
              />
            }
            {this.props.shape === 'round' && this.props.columns === 4 || this.props.numbered && <div className={style["line"]}> </div>}
            {(this.props.shape === 'round' && this.props.columns === 4) && <div className={style["podcast-subtext"]}> </div>}
            {this.props.numbered && <div className={style["number"]}>{("0" + parseInt(this.props.number+1)).slice(-2)}</div>}

            {['album', 'speaker', 'content_creator', 'artist', 'playlist', `${getActiveChannel() ? "" : 'video_content_creation'}`].includes(this.props.data.kind) &&
              data.type !== 'custom' &&
              <div className={style["menu"]}>
                {(!getActiveChannel() || getUseTools()) || (getActiveChannel() && channelCanAddToFavorites()) ?
                  <>
                    <div className={`${style['star']} ${data.favorite && style['active']}`}>
                      {this.makeFavoriteButton(data, data.favorite, !getActiveChannel() ? 'star' : getStoredChannel().preferences.fav_icon)}
                    </div>

                    {(!getActiveChannel() || getUseTools()) &&
                    <>
                      {data.kind === "content_creator" &&
                        <AddToPlaylist
                          clientId={getActiveClient()}
                          content='contentCreators'
                          item={data}
                          contentCreatorType={data && data.type && data.type.slug}
                          extraClass={style['list']}
                          tooltipTitle={localization.get("add_to_playlist")}
                        />
                      }
                      {data.kind === "video_content_creation" &&
                        <>
                          {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                            <AddToPlaylist 
                              clientId={getActiveClient()}
                              content='contentCreations'
                              item={data}
                              extraClass={style['list']}
                              tooltipTitle={localization.get("ranking_config.playlist_add")}
                            />
                          }
                          <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                            <div className={style['list']}>
                              <Person onClick={(e) => {
                                e.stopPropagation();
                                this.openContentCreatorModal()
                              }}/>
                            </div>
                          </Tooltip>
                        </>
                      }
                    </>
                    }

                    {data.kind === "playlist" &&
                    <Tooltip placement="bottom" title={localization.get("share")}>
                      <div className={style['list']}>
                        <Share onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.sharePlaylist(data.id);
                        }}/>
                      </div>
                    </Tooltip>
                    }

                  </>
                  :
                  <>
                    {data.kind === "video_content_creation" &&
                    <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                      <div className={style['list']}>
                        <Person onClick={(e) => {
                          e.stopPropagation();
                          this.openContentCreatorModal()
                        }}/>
                      </div>
                    </Tooltip>
                    }
                  </>
                }
              </div>
            }
          </div>
          }
        </Link>
      {this.renderShareDialog()}
      </>
    );
  };
}

export default HomeElementCarouselItemOnlyImage;
