import {del as deleteFetch, post as postFetch, put} from '../../../utils'

import get from './get'
import {isNullOrUndefined} from "util";
import getMetadata from "./getMetadata";
import getById from "./getById";
import moment from "moment";

const makeItemForm = ({
  active,
  title,
  subtitle,
  link,
  imageId,
  video,
  newTab,
  preview,
  hasButton,
  button,
  halfHeight,
  channelId,
  translations
}, usePUTMethod) => {
  const form = new FormData()
  form.append('active', active ? 1 : 0)
  form.append('new_tab', newTab ? 1 :0)
  form.append('half_height', halfHeight ? 1 :0)
  form.append('has_button', hasButton ? 1 :0)
  form.append('subtitle', subtitle)
  if (title) form.append('title', title)
  if (channelId) form.append('channel_id', channelId)
  if (!isNullOrUndefined(link)) form.append('link', link)
  if (imageId) form.append('imageId', imageId)
  if (video) form.append('videoId', video)
  if (preview) form.append('previewId', preview)
  if (button) {
    form.append('position', button.position);
    form.append('background_color', button.background_color);
    form.append('font_color', button.font_color);
    form.append('textEs', button.textEs || button.text);
    form.append('textEn', button.textEn);
    form.append('shape', button.shape);
    form.append('icon', button.icon);
    form.append('padding', button.padding);
    form.append('fontSize', button.fontSize);
  }

  console.log(translations)
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.title)) form.append(`title_${translation.language}`, translation.title);
      if (!isNullOrUndefined(translation.subtitle)) form.append(`subtitle_${translation.language}`, translation.subtitle);
    });
  }

  if (usePUTMethod) form.append('_method', 'put')
  return form
}

const makeElementForm = ({
  testing= false,
  translations,
  type,
  columns,
  dataSource,
  order,
  includeGold,
  rowsToShow,
  rowsToShowMobile,
  onlyImage,
  catalogs,
  artists,
  contentCreatorType,
  selectedTags,
  unselectedTags,
  selectedContentCreatorTypes,
  channelId,
  link,
  channelPlaylist,
  isCreating,
  shape,
  fixedTitle,
  withBackground,
  numbered,
  navbarViewType,
  parent,
  featuredType,
  useFeaturedFormat,
  singleContentCreator,
  square,
  hasMargin,
  spacing,
  newTab,
  imageId,
  sectionId,
  section,
  rounded,
  halfHeight,
  mobileHalfHeight,
  backgroundColor,
  backgroundStyle,
  globalPlaylists,
  imageMobileId,
  filterByDate,
  rangeStartDate,
  rangeEndDate,
  uniqueTexts,
  videoId,
  showAuthor,
  textPosition,
  withText,
  playOnHover,
  limitContent,
  filterByPublishDate,
  rangeStartPublishDate,
  rangeEndPublishDate,
  subHours,
  orderBy,
  lang,
  videos,
  images,
  visibility = null,
  alternative,
  mobileFontResize,
  contentType,
  embed,
  embedElement,
  gridFeatured,
  smartSearchContentType,
  smartSearchContentCreatorType,
  availableTagGroups,
  dateSearch,
  searchViewType,
  reference,
  useRegular,
  fontColor,
  pillBackgroundColor,
  pillFontColor,
  mobileGrid,
  isPaginatedGrid,
  hideStarterText,
  useDots,
  useSearchInput,
  people,
  linkTo,
  activeColor,
  hoverBackgroundColor,
  hoverTextColor
}, usePUTMethod) => {
  const form = new FormData()

  if(!testing && translations) {
     translations.forEach(translation => {
      if (!isNullOrUndefined(translation.title)) form.append(`translations[${translation.language}][title]`, translation.title)
      if (!isNullOrUndefined(translation.additions)) form.append(`translations[${translation.language}][additions]`, translation.additions)
      if (!isNullOrUndefined(translation.synopsis)) form.append(`translations[${translation.language}][synopsis]`, translation.synopsis)
    })
  }

  videos && videos.length && videos.forEach(video => {
    if (!isNullOrUndefined(video.video_id)) form.append(`videos[${video.language}][video_id]`, video.video_id)
    if (!isNullOrUndefined(video.play_on_hover)) form.append(`videos[${video.language}][play_on_hover]`, video.play_on_hover ? 1 : 0)
    if (!isNullOrUndefined(video.image_id)) form.append(`videos[${video.language}][image_id]`, video.image_id)
  })

  images && images.length && images.forEach(image => {
    if (!isNullOrUndefined(image.image_id)) form.append(`images[${image.language}][image_id]`, image.image_id)
    if (!isNullOrUndefined(image.mobile_image_id)) form.append(`images[${image.language}][mobile_image_id]`, image.mobile_image_id)
    if (!isNullOrUndefined(image.new_tab)) form.append(`images[${image.language}][new_tab]`, image.new_tab)
    if (!isNullOrUndefined(image.link)) form.append(`images[${image.language}][link]`, image.link)
  })

  if (type) form.append('type', type)
  if (columns) form.append('columns', columns)
  if (rowsToShow) form.append('rows_to_show', rowsToShow)
  if (rowsToShowMobile) form.append('rows_to_show_mobile', rowsToShowMobile)
  if (dataSource) form.append('data_source', dataSource)
  if (channelId) form.append('channel_id', channelId)
  if (link) form.append('link', link)
  if (!isNullOrUndefined(onlyImage)) form.append('only_image', onlyImage ? 1 : 0)
  if (order) form.append('order', order)
  form.append('include_gold', includeGold ? 1 : 0)
  if (usePUTMethod) form.append('_method', 'put')
  if (catalogs && catalogs.length > 0) catalogs.forEach(catalog => form.append('catalogs_ids[]', catalog))
  if (artists && artists.length > 0) artists.forEach(artist => form.append('artists_ids[]', artist))
  if (contentCreatorType) form.append('content_creator_type', contentCreatorType)
  if (selectedTags && selectedTags.length > 0) selectedTags.forEach(x => form.append("tags_ids[]", x));
  if (unselectedTags && unselectedTags.length > 0) unselectedTags.forEach(x => form.append("not_tags_ids[]", x));
  if (contentType) form.append('content_type', contentType)
  if (channelPlaylist) form.append('channel_playlist_id', channelPlaylist)
  if (isCreating) form.append('creating', isCreating ? 1 : 0)
  if (rounded) form.append('rounded', rounded ? 1 : 0)
  if (halfHeight) form.append('half_height', halfHeight ? 1 : 0)
  if (mobileHalfHeight) form.append('mobile_half_height', mobileHalfHeight ? 1 : 0)
  if (shape) form.append('shape', shape)
  if (!isNullOrUndefined(fixedTitle)) form.append('fixed_title', fixedTitle ? 1 : 0)
  if (!isNullOrUndefined(withBackground)) form.append('with_background', withBackground ? 1 : 0)
  if (!isNullOrUndefined(numbered)) form.append('numbered', numbered ? 1 : 0)
  if (navbarViewType) form.append('view_type', navbarViewType)
  if (parent) form.append('parent', parent.id)
  if (!isNullOrUndefined(useFeaturedFormat)) form.append('featured_format', useFeaturedFormat ? 1 : 0)
  if (featuredType) {
    form.append('inverted', featuredType === "inverted" && !numbered ? 1 : 0)
    form.append('editorial', featuredType === "editorial" && !numbered ? 1 : 0)
    form.append('editorial_inverted', featuredType === "editorial_inverted" && !numbered ? 1 : 0)
    form.append('news', featuredType === "news" ? 1 :0)
    form.append('movie', featuredType === "movie" ? 1 :0)
  }
  if (singleContentCreator) {
    form.append('content_creator_id', singleContentCreator.value)
    form.append('content_creator_name', singleContentCreator.label)
  }
  if (['none', 'small', 'medium'].includes(spacing)) form.append('spacing', spacing)
  if (!isNullOrUndefined(square)) form.append('square', square ? 1 : 0)
  if (!isNullOrUndefined(hasMargin)) form.append('has_margin', hasMargin ? 1 : 0)
  if (!isNullOrUndefined(newTab)) form.append('new_tab', newTab ? 1 : 0)
  if (imageId) form.append('image_id', imageId)
  if (videoId) form.append('video_id', videoId)
  if (imageMobileId) form.append('mobile_image_id', imageMobileId)
  if (sectionId) form.append('section_id', sectionId)
  if (section) {
    form.append('target_section_id', section.value)
    form.append('target_section_name', section.label)
  }
  form.append('background_color', backgroundColor)
  form.append('font_color', fontColor)
  form.append('background_style', backgroundStyle)
  if (globalPlaylists && globalPlaylists.length > 0) globalPlaylists.forEach(x => form.append("global_playlists_ids[]", x.value));

  if (filterByDate) form.append('filter_by_date', filterByDate);
  if (filterByDate === "range" && rangeStartDate) form.append('start_date', moment(rangeStartDate).format('YYYY-MM-DD'));
  if (filterByDate === "range" && rangeEndDate) form.append('end_date', moment(rangeEndDate).format('YYYY-MM-DD'));

  if (filterByPublishDate) form.append('filter_by_publish_date', filterByPublishDate);
  if (subHours && subHours > 0) form.append('sub_hours', subHours);
  if (filterByPublishDate === "range" && rangeStartPublishDate) form.append('start_publish_date', moment(rangeStartPublishDate).format('YYYY-MM-DD'));
  if (filterByPublishDate === "range" && rangeEndPublishDate) form.append('end_publish_date', moment(rangeEndPublishDate).format('YYYY-MM-DD'));

  if(!testing && uniqueTexts) {
    uniqueTexts.forEach(translation => {
      if (translation.text !== "") form.append(`text_${translation.language}`, translation.text)
    })
  }

  form.append('show_author', showAuthor ? 1 : 0 )
  if (featuredType === "news") {
    if (shape === 'x1') {
      form.append('text_position', ['right', 'left'].includes(textPosition) ? textPosition : 'right');
    } else {
      form.append('text_position', ['default', 'inverted'].includes(textPosition) ? textPosition : 'default');
    }
  } else {
    if (textPosition) form.append("text_position", textPosition);
  }
  form.append("with_text", withText ? 1 : 0);
  form.append('play_on_hover', playOnHover ? 1 : 0)
  if (limitContent || limitContent === 0) form.append('limit_content', limitContent)

  if (orderBy) form.append('order_by', orderBy)
  if (lang) form.append('lang', lang)

  if (!isNullOrUndefined(visibility)) form.append('visible', visibility ? 1: 0)
  if (!isNullOrUndefined(alternative)) form.append('alternative', alternative ? 1: 0)
  if (mobileFontResize) form.append('mobile_font_resize', mobileFontResize)
  if (!isNullOrUndefined(embed)) form.append('embed', embed)
  if (!isNullOrUndefined(embedElement)) form.append('embed_element', embedElement ? 1 : 0)
  if (!isNullOrUndefined(gridFeatured)) {
    form.append('grid_featured', gridFeatured ? 1 : 0)
    if (gridFeatured) {
      form.append('shape', columns === 2 ? 'x2' : 'x4')
    }
  }

  if (dataSource == 'lite_smart_search') {
    form.append('content_type', smartSearchContentType)
    form.append('content_creator_type', smartSearchContentCreatorType)
    availableTagGroups.map(tg => form.append('tag_groups[]', tg.value))
    form.append('date_search', dateSearch)
    form.append('search_view_type', searchViewType)
  }

  form.append('reference', reference) 
  if (pillBackgroundColor) form.append('pill_background', pillBackgroundColor) 
  if (pillFontColor) form.append('pill_color', pillFontColor) 

  if (!isNullOrUndefined(useRegular)) form.append('use_regular', useRegular ? 1: 0)
  if (!isNullOrUndefined(mobileGrid)) form.append('mobile_grid', mobileGrid ? 1: 0)
  if (!isNullOrUndefined(isPaginatedGrid)) form.append('paginated_grid', isPaginatedGrid ? 1: 0 )
  if (!isNullOrUndefined(hideStarterText)) form.append('hide_starter_text', hideStarterText ? 1: 0 )
  if (!isNullOrUndefined(useDots)) form.append('use_dots', useDots ? 1: 0 )
  if (!isNullOrUndefined(useSearchInput)) form.append('use_search_input', useSearchInput ? 1: 0 )
  if (people && people.length > 0) people.forEach(person => form.append('people_ids[]', person))
  if (!isNullOrUndefined(linkTo)) form.append('link_to', linkTo)
  if (!isNullOrUndefined(activeColor)) form.append('active_color', activeColor) 
  if (!isNullOrUndefined(hoverBackgroundColor)) form.append('hover_background_color', hoverBackgroundColor) 
  if (!isNullOrUndefined(hoverTextColor)) form.append('hover_text_color', hoverTextColor)
     
  return form
}

const makeElementFormOrder = (orderData) => {
  const form = new FormData()
  if (orderData.length > 0) {
    orderData.forEach((homeElement,index) => {
      form.append(`home_elements[${index}][id]`, homeElement.id);
      form.append(`home_elements[${index}][order]`, homeElement.order);
    })
  }

  form.append('_method', 'put')

  return form
}

const createBannerItem = (host) => (clientId, elementId, data, onProgress) => postFetch(`${host}/clients/${clientId}/home/elements/${elementId}/items`, makeItemForm(data), {}, onProgress)

const deleteBannerItem = (host) => (clientId, elementId, itemId) => deleteFetch(`${host}/clients/${clientId}/home/elements/${elementId}/items/${itemId}`)

const updateBannerItem = (host) => (clientId, elementId, itemId, data, onProgress) => postFetch(`${host}/clients/${clientId}/home/elements/${elementId}/items/${itemId}`, makeItemForm(data, true), {}, onProgress)

const del = (host) => (clientId, elementId) => deleteFetch(`${host}/clients/${clientId}/home/elements/${elementId}`)

const create = (host) => (clientId, data) => postFetch(`${host}/clients/${clientId}/home/elements`, makeElementForm(data))
  .then(response => response.json())

const update = (host) => (clientId, elementId, data) => postFetch(`${host}/clients/${clientId}/home/elements/${elementId}`, makeElementForm(data, true))
  .then(response => response.json())

const updateOrder = (host) => (clientId, order) => postFetch(`${host}/clients/${clientId}/home/elements/order`, makeElementFormOrder(order))

const test = (host) => (clientId, data) => postFetch(`${host}/clients/${clientId}/home/elements/test`, makeElementForm(data)).then(response => response.json())

const updateActive = (host) => (clientId, id, active) => put(`${host}/clients/${clientId}/home/elements/${id}/active`, JSON.stringify({ active: active}))


export default host => ({
  get: get(host),
  getById: getById(host),
  createBannerItem: createBannerItem(host),
  deleteBannerItem: deleteBannerItem(host),
  updateBannerItem: updateBannerItem(host),
  delete: del(host),
  create: create(host),
  update: update(host),
  updateOrder: updateOrder(host),
  test: test(host),
  getMetadata: getMetadata(host),
  updateActive: updateActive(host),
})
