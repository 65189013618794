import React, {Component} from "react";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import HomeElementGridContainer from "../Home/HomeElements/HomeElementGridContainer";
import {request} from '../../api-client'
import localization from '../../config/localization';
import GridItem from "../Home/HomeElements/GridItem";
import HomeElementFeaturedItem from "../Home/HomeElements/HomeElementFeaturedItem";
import EventManager, {events} from "../../utils/EventManager";
import HomeElementCarousel from "../Home/HomeElements/HomeElementCarousel";
import _ from 'lodash'
import ErrorBoundary from "../../components/Letflow/ErrorBoundary";
import {
  getActiveChannel,
  getActiveClient, getCurrentSection, getStoredUser, getStoredChannel, setPageTitle, rootPath,
   getAlbumFavorites, getArtistFavorites, getContentCreatorFavorites, getContentCreationFavorites, 
} from "../../api-client/core/authentication/utils";
import {Helmet} from "react-helmet/es/Helmet";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import DefaultBanner from "../Home/HomeElements/DefaultBanner";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../../assets/img/loading_spinner.gif";
import {getValueFromQueryParam, pushIfDoesNotExist} from "../../utils";
import browserHistory from "../../utils/browserHistory";
import styled from "styled-components";
import Slider from "react-slick";
import PrevArrow from "../../components/Letflow/Arrows/PrevArrow";
import NextArrow from "../../components/Letflow/Arrows/NextArrow";
import {convertFromRaw, EditorState} from "draft-js";
import RichEditor from "../../components/Letflow/RichTextEditor/EditorialEditor";
import HomeElementUniqueContainer from "../Home/HomeElements/HomeElementUnique";
import {get} from 'lodash';
import AvailableLanguages from "../../config/localization/availableLanguages.json";
import {navigateToCurrentLangRoute} from "../../routes/channel";
import analyticsInstance from "../../analytics/analyticsInstance";
import HomeElementCarouselItem from "../Home/HomeElements/HomeElementCarouselItem";
import SupportFloatingButton from "../../components/Letflow/SupportFloatingButton";
import HomeElementEventGrid from "../Home/HomeElements/HomeElementEventGrid";


class Channel extends Component {

  constructor(props) {
    super(props)

    const firstElementsLength = get(props, "firstElements", []).length;
    const bannerElementsLength = get(props, "bannerElements", []).length;

    this.state = {
      banners: get(props, "bannerElements", []),
      failedLoading: false,
      channelElementsMetadata: props.channelElements,
      channelElements: get(props, "firstElements", []),
      channel: props.channel ? props.channel : null,
      favorites: getAlbumFavorites(),
      artistsFavorites: getArtistFavorites(),
      contentCreatorsFavorites: getContentCreatorFavorites(),
      contentCreationsFavorites: getContentCreationFavorites(),
      playlistsFavorites: [],
      pageReady: false,
      currentPage: firstElementsLength === 0 ? 0 : 1,
      tempElements: [],
      perPage: firstElementsLength > 0 ? firstElementsLength: 5,
      requesting: false,
      loading: !(firstElementsLength || bannerElementsLength),
      noCache: window.location.search.includes("?no_cache=true")
    }
  }

  channelId = () => getActiveChannel()

  componentDidMount = () => {
    if (
      getCurrentSection() &&
      getCurrentSection().main &&
      getStoredChannel().domain &&
      getStoredChannel().languages.length > 1 &&
      window.domainLanguage === 'es' &&
      getStoredChannel().language !== 'es' &&
      window.domainLanguage !== getStoredChannel().language &&
      (!localStorage.getItem("preferredLang") || localStorage.getItem("preferredLang") !== 'es')
    ) {
      if (localStorage.getItem("preferredLang")) {
        navigateToCurrentLangRoute(localStorage.getItem("preferredLang"));
      }else {
        navigateToCurrentLangRoute(getStoredChannel().language);
      }
    }
    if (!getCurrentSection()) {
      browserHistory.push(rootPath('/not-found'))
      return;
    }
    const noCache = window.location.search.includes("?no_cache=true")
  
    if (this.state.channelElements.length === 0 && this.state.banners.length === 0) {
        this.requestChannelElements()
    } else if (this.shouldLoadMore() && !(window.domainTargetVisibility === "public" || !noCache)) {
        this.requestChannelElements()
    }

    setPageTitle()
    // GAevent(`Seccion`, "Visualizar", getCurrentSection().id)
    analyticsInstance.visitContent({
      name: getStoredChannel().name,
      content_type: "Section",
    })
    let openModalIfQueryParamPresent = (queryParam) => {
      let value = getValueFromQueryParam(queryParam);
      if (value) {
        if (queryParam !== "material") {
          const content = value.split('-');
          const contentId = content[0]
          content.shift();
          EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
            id: contentId,
            dataType: queryParam,
            dataName: content.toString(),
          });
        }else {
          EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id: value});
        }
      }
    }
    openModalIfQueryParamPresent("contenido");
    openModalIfQueryParamPresent("album");
    openModalIfQueryParamPresent("artista");
    openModalIfQueryParamPresent("playlist");
    openModalIfQueryParamPresent("material");
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevState.tempElements !== this.state.tempElements) {
      const {tempElements, channelElements, channelElementsMetadata} = this.state;

      const someFunction = () => {
        const lastElement = channelElements[channelElements.length - 1];
        let addIndex = channelElementsMetadata.find(cem => cem.type === 'banner') ? 1 : 0;
        let nextElementIndex = addIndex;
        if (lastElement) {
          nextElementIndex = channelElementsMetadata.findIndex(cem => cem.id === lastElement.id) + addIndex;
        }
        const lastElementToAdd = tempElements.find(te => {
          const nextElementMetadata = channelElementsMetadata[nextElementIndex];
          return nextElementMetadata && te.id === nextElementMetadata.id;

        });
        if (lastElementToAdd) {
          pushIfDoesNotExist(channelElements, lastElementToAdd);
          someFunction();
        } else if ((channelElementsMetadata.length > channelElements.length + 1) && channelElements.every(elem => elem && elem.items && elem.items.data.length === 0)) {
          this.requestChannelElements();
        }
      }

      tempElements.forEach(() => someFunction());


      this.setState({channelElements});
    }
  }


  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      this.setState({channel: nextProps.channel})

      if (!nextProps.showModal) {
        if (window.location.href.indexOf('?contenido=') > 0 || window.location.href.indexOf('?album=') > 0 || window.location.href.indexOf('?artista=') > 0) {
          const urlParameter = window.location.href.split("?")[1].split('=');
          const content = urlParameter[1].split('-');
          const contentId = content[0]
          content.shift()

          EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
            id: contentId,
            dataType: urlParameter[0],
            dataName: content.toString()
          });
        }
      }
    }
  }

  requestChannelElements = () => {
    if (!this.state.requesting) {
      this.setState({
          currentPage: this.state.currentPage + 1,
          requesting: false,
        }, () => {
          this.state.channelElementsMetadata
            .slice(this.state.currentPage == 1 ? 0 : this.state.perPage * (this.state.currentPage - 1), this.state.perPage * this.state.currentPage)
            .forEach(element => {
              if (element.type === "banner") {
                if (this.state.channel.show_banner !== 'none') {
                  if (element.items.data && element.items.data.length > 0) {
                    this.setState({banners: element.items.data, loading: false})
                  } else {
                    this.setState({loading: false, requesting: false})
                  }
                }
              } else {
                if (element.type === "unique") {
                  this.setState(prevState => ({
                    tempElements: prevState.tempElements.concat([element]),
                    requesting: false,
                    loading: false
                  }))
                }else {
                  request.channel.getElementById(this.channelId(), element.id, this.state.noCache)
                    .then(homeElement => {
                      if (this.state.channel.show_banner !== 'none') {
                        this.setState(prevState => ({
                          tempElements: prevState.tempElements.concat([homeElement]),
                          requesting: false
                        }))
                      } else {
                        this.setState(prevState => ({
                          tempElements: prevState.tempElements.concat([homeElement]),
                          requesting: false,
                          loading: false
                        }))
                      }
                    })
               }
            }
          })
        }
      )
    }
  }

  updateElement = element => {
    this.setState(prevState => ({
      channelElements: prevState.channelElements.map(e => e.id === element.id ? element : e)
    }))
  }

  addAboutToHomeElements = channel => {
    let channelElements = this.state.channelElements;
    let aboutState = false;

    if (channel.show_about === "body" && channel.about && getCurrentSection().id === getStoredChannel().sections[0].id) {
      aboutState = !channel.about.startsWith('<') && JSON.parse(channel.about);
      if (aboutState) {
        let mobileResize = (channel.preferences && channel.preferences.mobile_font_resize) ? (Number(channel.preferences.mobile_font_resize) / 100) : 1;
        if ((window.innerWidth <= 600) && (mobileResize != 1)) {
          let blocks = aboutState.blocks;
          if (blocks) {
            blocks.map(b => {
              b.inlineStyleRanges = b.inlineStyleRanges.map(isr => {
                isr.style = (isr.style && isr.style.startsWith("fontsize-")) ? `fontsize-${Math.round(Number(isr.style.split("fontsize-")[1]) * mobileResize)}` : isr.style;
                return isr;
              });
              return b;
            });
          }
        }
      }
    }

    let elementStyles = {
      overflow: 'hidden',
      backgroundColor: 'var(--main-color, white)',
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    };

    if (getCurrentSection().image) elementStyles = {...elementStyles, backgroundColor: "transparent"}

    return (
      <>
        {channel.show_about === "body" && channel.about && getCurrentSection().id === getStoredChannel().sections[0].id &&
        (window.innerWidth > 500 || channelElements.length === 0) &&
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{padding: "50px 66px 0", overflowWrap: "break-word", maxWidth: 1920}}>
            {channel.about.startsWith('<') ?
                <div dangerouslySetInnerHTML={{__html: channel.about}} /> :
                <RichEditor
                  editorState={EditorState.createWithContent(convertFromRaw(aboutState || JSON.parse(channel.about)))}
                  readOnly={true}
                  onChange={() => console.log('read only')}
                />
              }
            </div>
          </div>
        }

        <InfiniteScroll
          style={elementStyles}
          dataLength={this.state.channelElements.length}
          next={this.requestChannelElements}
          hasMore={this.state.channelElementsMetadata.length > this.state.channelElements.length + 1}
          loader={<div
            style={{height: "70px", paddingTop: 20, paddingBottom: 20, width: "100%", display: "flex", justifyContent: "center", alignContent: "center", backgroundColor: (this.state.channelElements.length > 0 && this.state.channelElements[this.state.channelElements.length-1].background_style === '100') ? this.state.channelElements[this.state.channelElements.length-1].background_color : ((getCurrentSection() && getCurrentSection().image) ? 'transparent' : "var(--main-color, white)")}}
          >
            <img style={{width: 50, height: 50}} src={LoadingSpinner} alt="Loading logo"/>
          </div>}
        >
          {channelElements.map((homeElement, index) => {
            return (
              homeElementToComponent(
                this.props,
                getAlbumFavorites(),
                getArtistFavorites(),
                this.state.playlistsFavorites,
                getContentCreatorFavorites(),
                getContentCreationFavorites(),
                () => !this.state.pageReady && this.setState({pageReady: true}),
                (element) => this.updateElement(element)
              )(homeElement, (index+1<=channelElements.length-1) ? channelElements[index+1] : null)
            )
          })}
        </InfiniteScroll>
      </>
    )
  }

  render = () => {
    const channel = this.props.channel;

    let bannerSettings = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      beforeChange: () => EventManager.getInstance().dispatch(events.CHANGE_VIDEO_SLIDER_BANNER),
      onSwipe: () => EventManager.getInstance().dispatch(events.CHANGE_VIDEO_SLIDER_BANNER),
      prevArrow: <PrevArrow
        home={true}
        className={"banner-arrow"}
        overrideStyle={{transform: `translate(0, -${window.innerWidth < 770 ? 55 : 215}%)`, marginLeft: window.innerWidth < 770 ? 20 : 50, zIndex: 100, cursor: "pointer", fontSize: 50, color: "rgba(255,255,255,0.6)"}}
      />,
      nextArrow: <NextArrow
        home={true}
        className={"banner-arrow"}
        overrideStyle={{transform: `translate(0, -${window.innerWidth < 770 ? 55 : 215}%)`, marginRight: window.innerWidth < 770 ? 20 : 50,zIndex: 100, cursor: "pointer", fontSize: 50, color: "rgba(255,255,255,0.6)"}}/>,
      dots: false,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };

    if (this.state.failedLoading) {
      return <div>Algo salio mal, refresque la pagina</div>
    } else {
      return (
        <>
          <Helmet>
            <meta name="ROBOTS" content="INDEX, FOLLOW"/>
            {this.props.channel.image && <meta property="og:image" content={this.props.channel.image.url} />}
          </Helmet>
          <div id="innerinner"
               style={{
                 ...(getCurrentSection() && getCurrentSection().image) ?
                   {
                     paddingTop: 20,
                     backgroundImage: `url(${request.file.image.makeCroppedUrl(getCurrentSection().image.id, 1500, 840)})`,
                     backgroundAttachment: 'fixed',
                     backgroundSize: "cover"
                   } : {marginTop: 20}
               }}>
            {!this.state.loading ?
              <>
                {this.state.banners.length ?
                  <>
                    {this.state.banners.length > 1 && this.state.channel.show_banner === 'multi' ?
                      <Slider {...bannerSettings} >
                        {this.state.banners.map(banner =>
                          <DefaultBanner channel={channel} banner={banner}/>
                        )}
                      </Slider>
                      :
                      <DefaultBanner channel={channel} banner={this.state.banners[0]}/>
                    }
                  </>
                  :null
               }
                {this.addAboutToHomeElements(channel)}

              </> :
              <SkeletonTheme color={"#ddd"}>
                {this.state.channel.show_banner !== 'none' &&
                <Skeleton height={550} duration={1.5}/>
                }
                <br/><br/>
                <br/><br/>
                <Skeleton height={50} duration={1.5}/>
                <br/>
                <br/>
                <span style={{marginLeft: "1%", marginRight: ".5%"}}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
                <span style={{marginLeft: ".5%", marginRight: ".5%"}}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
                <span style={{marginLeft: ".5%", marginRight: ".5%"}}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
                <span style={{marginLeft: ".5%", marginRight: "1%"}}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
              </SkeletonTheme>
            }
            {channel.use_floating_button && <SupportFloatingButton/>}
          </div>
        </>
      )
    }
  }

  shouldLoadMore = () => {
    const container = document.getElementById('innerinner');
    if (!container) return false;
    
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    
    return containerHeight >= contentHeight && 
           this.state.channelElementsMetadata.length > this.state.channelElements.length + 1;
  }
}

const makeHomeElementProp = (homeElement, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites) => {
  
  switch (homeElement.data_source) {
    case 'user_library':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            recentlyCreated: item.recently_created,
            recentlyUpdated: item.recently_updated,
            shortDescription: item.subtitle,
            description: item.description,
            favorite: (favorites && item.kind === 'album') ? favorites.includes(item.id) : (playlistFavorites && item.kind === "playlist") ? playlistFavorites.includes(item.id) : false,
            tracks: _.concat(item.musics || [], item.voices || [])
          }))
        }
      }
    case 'artist':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.name,
            shortDescription: item.description,
            kind: homeElement.data_source,
            favorite: artistsFavorites ? artistsFavorites.includes(item.id) : false,
          }))
        }
      }

    case 'channel_playlist':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.name,
            shortDescription: item.description,
            kind: 'content_creator',
            favorite: (contentCreatorsFavorites && homeElement.data_source === "content_creator")
              ? contentCreatorsFavorites.includes(item.id)
              : false,
          }))
        }
      }
    case 'content_creator':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.name,
            shortDescription: item.description,
            kind: homeElement.data_source,
            favorite: (contentCreatorsFavorites && homeElement.data_source === "content_creator")
              ? contentCreatorsFavorites.includes(item.id)
              : false,
          }))
        }
      }

    case 'video_content_creation':
      const materialIds = homeElement.items.data.map((item, index) => ({index, id: item.id}))

      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.title,
            shortDescription: item.description,
            kind: homeElement.data_source,
            favorite: (contentCreationsFavorites && homeElement.data_source === "video_content_creation")
              ? contentCreationsFavorites.includes(item.id)
              : false,
            materialIds: materialIds,
          }))
        }
      }
    case 'production':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.project_name,
            shortDescription: item.project_details,
            kind: homeElement.data_source
          }))
        }
      }
    case 'recommended':
      const data = homeElement.items.data.filter(playlist => {
        if (!!get(getStoredUser(), 'brand.id', null) && get(playlist, "brands", []).length > 0) {
          return playlist.brands.some(brand => brand.id === get(getStoredUser(), 'brand.id'))
        } 
        return true;  
      }).map(item => ({
        ...item,
        recentlyCreated: item.recently_created,
        recentlyUpdated: item.recently_updated,
        shortDescription: item.short_description,
        description: item.description,
        longDescription: item.long_description,
        tracks: item.musics,
      }))

      const nonPlaylists = data.filter(x => x.kind !== 'playlist')
      const playlists = data.filter(x => x.kind === 'playlist').sort(compareValues('favorite', 'desc'))

      return {...homeElement, items: {data: nonPlaylists.concat(playlists)}}

      case 'custom':
        return {
          ...homeElement,
          items: {
            data: homeElement.items.data.map(item => ({
              ...item,
              recentlyCreated: item.recently_created,
              recentlyUpdated: item.recently_updated,
              shortDescription: item.short_description,
              description: item.description,
              longDescription: item.long_description,
              kind: 'custom',
            }))
          }
        }

    default:
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            recentlyCreated: item.recently_created,
            recentlyUpdated: item.recently_updated,
            shortDescription: item.short_description,
            description: item.description,
            longDescription: item.long_description,
            kind: 'album',
            tracks: item.musics,
            favorite: favorites.includes(item.id),
          }))
        }
      }
  }
}

const compareValues = (key, order = 'asc') => {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = (typeof a[key] === 'string') ?
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ?
      b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

const makeTrackTypeProp = homeElement => {
  switch (homeElement.data_source) {
    case 'user_library':
      return (itemType) => itemType
    default:
      return () => 'music'
  }
}

const makeDataTypeProp = homeElement => {
  switch (homeElement.data_source) {
    case 'voice_over':
      return 'speaker';
    default:
      return 'album';
  }
}

const makeRequestElementProp = (homeElement) => {
  switch (homeElement.data_source) {

    case 'recommended':
      return async (id, itemKind) => {

        // if (itemKind === 'recents') {
        //   const recents = await request.music.recents(getActiveClient())
        //   return {
        //     title: localization.get('recents'),
        //     tracks: recents,
        //     kind: itemKind
        //   }
        // }

        if (itemKind === 'playlist') {
          const playlist = await request.playlist.getGlobal(id)

          const selectedTags = playlist.tags.filter(tag => tag.wanted === 1).map(tag => tag.id)
          const unselectedTags = playlist.tags.filter(tag => tag.wanted === 0).map(tag => tag.id)

          const tracks = await request.music.getAllByPlaylistForClient(getActiveClient(), id, selectedTags, unselectedTags, playlist.query || '', "recentLicenses;audio:id,duration,url")
          return {
            ...playlist,
            tracks,
            kind: itemKind,
          }
        }
      }

    case 'user_library':
      return async (id, itemKind, channelId, savedQuery) => {

        const fetchElement = async () => {

          if (itemKind === 'favorites' || itemKind === 'recents') {
            return await request.music[itemKind](channelId)
          }

          if (itemKind === 'album') {
            const album = await request.album.getForClient(id, 'include=image');
            const musics = await request.music.getAllByAlbumForClient(channelId, id, 'include=recentLicenses.user;versions_count;audio:id,duration,url;tags;favorite;channelFavorite;audio.waveform;albums.image;artist')
            return {...album, musics}
          }

          if (itemKind === 'savedQuery') {
            const savedQuery = await request.savedQuery.get(id);
            const musics = await request.music.getAllFromQuery(channelId, savedQuery.tags.map(tag => tag.id), savedQuery.query)
            return {...savedQuery, musics}
          }

          if (itemKind === 'playlist') {
            const playlist = await request.playlist.get(id)
            const musics = await request.music.getAllByPlaylistForClient(channelId, id)
            return {...playlist, musics}
          }

          return await request[itemKind].get(id)
        }

        const element = await fetchElement()

        return {
          id: Array.isArray(element) ? null : element.id,
          title: !Array.isArray(element) ? element.title : itemKind === 'favorites' ? localization.get('my_favorites') : itemKind === 'savedQuery' ? savedQuery.title : localization.get('recents'),
          image: Array.isArray(element) ? null : element.image || null,
          kind: itemKind,
          tracks: _.concat(
            element.musics ? element.musics.map(music => ({...music, type: 'music'})) : [],
            element.voices ? element.voices.map(voice => ({...voice, type: 'voice'})) : [],
            Array.isArray(element) ? element : []
          ),
          longDescription: Array.isArray(element) ? null : element.description,
          shortDescription: Array.isArray(element) ? null : element.subtitle,
          featuredImage: element.featuredImage || null,
          created_by_admin: element.created_by_admin || null
        }
      }
    default:
      return albumId => request.album.getForClient(albumId, 'include=image;artist')
        .then(album =>
          request.music.getAllByAlbumForClient(getActiveClient(), albumId, 'include=recentLicenses.user;versions_count;audio:id,duration,url;tags;favorite;channelFavorite;audio.waveform;albums.image;artist', album.type === 'library' ? 'created_at' : null, album.type === 'library' ? 'desc' : null)
            .then(musics => ({
              ...album,
              tracks: musics.map(music => ({...music, type: 'music'})),
              longDescription: album.long_description,
              shortDescription: album.short_description,
            }))
        )
  }
}

const homeElementToComponent = (props, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites, setPageReady, updateElement) => (homeElement, nextHomeElement=null) => {
  const hasTitle = title => {
    if (!title || title.length < 2) {
      return false;
    }else if(title.startsWith("{")) {
      let state = EditorState.createWithContent(convertFromRaw(JSON.parse(title)));
      const content = state.getCurrentContent();
      const text = content.getPlainText();

      return !!text;
    }

    return true;
  }

  let component
  let bottomPadding = (!nextHomeElement || hasTitle(nextHomeElement.title) || nextHomeElement.spacing == 'small') ? 20 : (nextHomeElement.spacing == 'medium') ? 40 : 0;
  switch (homeElement.type) {
    /* case 'banner':
       component = <DefaultBanner channel={props.channel} banner={homeElement.items.data} />
       break
     case 'default_banner':
       component = <DefaultBanner channel={props.channel} banner={homeElement.items.data} />
       break*/
    
    case 'grid':
      let maxPerRow = (homeElement.columns == 6 || (homeElement.shape === 'rectangle' && window.innerWidth < 600)) ? 6 : homeElement.columns;

      if ([2, 6, 4].includes(homeElement.columns)) {
        component = <HomeElementGridContainer
          {...props}
          bottomPadding={bottomPadding}
          favorites={favorites}
          maxPerRow={maxPerRow}
          shape={homeElement.shape}
          fixedTitle={homeElement.fixed_title}
          withBackground={homeElement.with_background}
          homeElement={makeHomeElementProp(homeElement, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites)}
          trackType={makeTrackTypeProp(homeElement)}
          dataType={makeDataTypeProp(homeElement)}
          requestElement={makeRequestElementProp(homeElement)}
          sizes={ maxPerRow == 6 ? 
            {
              xs: 6,
              sm: 4,
              md: 3,
              lg: 2,
              xl: 2,
            } 
          :
            {
              xs: 12,
              sm: 6,
              md: 3,
              lg: 3,
              xl: 3,
            }
          }
          itemComponent={homeElement.shape == "rectangle_event" ? HomeElementCarouselItem : (homeElement.grid_featured ? HomeElementFeaturedItem : GridItem)}
          rowsToShow={(window.innerWidth < 600 && homeElement.rows_to_show_mobile) ? homeElement.rows_to_show_mobile : homeElement.rows_to_show}
          home={true}
          hasMargin={homeElement.has_margin}
          spacing={homeElement.spacing}
          rounded={homeElement.rounded}
          backgroundColor={homeElement.background_color}
          backgroundStyle={homeElement.background_style}
          onlyImage={homeElement.only_image}
          withText={homeElement.with_text}
          numbered={homeElement.numbered}
          inverted={homeElement.inverted}
          news={homeElement.news || homeElement.news_inverted}
          showAuthor={homeElement.show_author}
          editorial={homeElement.editorial}
          editorialInverted={homeElement.editorial_inverted}
          square={homeElement.square}
          halfHeight={homeElement.half_height}
          mobileHalfHeight={homeElement.mobile_half_height}
          textPosition={homeElement.text_position || (homeElement.news_inverted ? 'inverted' : homeElement.news ? 'default' : null)}
          movie={homeElement.movie}
          useFeaturedFormat={homeElement.featured_format}
          columns={homeElement.columns}
          pillColor={homeElement.pill_color}
          pillBackground={homeElement.pill_background}
          paginated={homeElement.paginated_grid}
          showMore={!homeElement.paginated_grid ? !!homeElement.rows_to_show : homeElement.items.data.length % (homeElement.columns * homeElement.rows_to_show) != 0}
          requestTracks={homeElement.paginated_grid ? () => {
            request.channel.getElementById(getActiveChannel(), homeElement.id, false, `&page=${get(homeElement, 'currentPage', 1) + 1}`)
              .then(element => {
                const newElement = {
                  ...homeElement,
                  currentPage: get(homeElement, 'currentPage', 1) + 1,
                  items: {
                    data: homeElement.items.data.concat(element.items.data)
                  }
                }

                return updateElement(newElement);
              })
              .catch(error => {
                console.error('Error requesting more elements:', error);
              });
          }
        : undefined}
        />
          
      } else {
        component = null
      }
      break
    case 'featured':
    case 'carousel':
      component = <HomeElementCarousel
        {...props}
        bottomPadding={bottomPadding}
        favorites={favorites}
        playlistFavorites={playlistFavorites}
        contentCreatorsFavorites={contentCreatorsFavorites}
        contentCreationsFavorites={contentCreationsFavorites}
        onlyImage={homeElement.only_image}
        numbered={homeElement.numbered}
        shape={homeElement.shape}
        useFeaturedFormat={homeElement.featured_format}
        hasMargin={homeElement.has_margin}
        columns={homeElement.columns}
        inverted={homeElement.inverted}
        news={homeElement.news || homeElement.news_inverted}
        showAuthor={homeElement.show_author}
        editorial={homeElement.editorial}
        editorialInverted={homeElement.editorial_inverted}
        homeElement={makeHomeElementProp(homeElement, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites)}
        trackType={makeTrackTypeProp(homeElement)}
        dataType={makeDataTypeProp(homeElement)}
        requestElement={makeRequestElementProp(homeElement)}
        square={homeElement.square}
        spacing={homeElement.spacing}
        home={true}
        rounded={homeElement.rounded}
        halfHeight={homeElement.half_height}
        mobileHalfHeight={homeElement.mobile_half_height}
        backgroundColor={homeElement.background_color}
        backgroundStyle={homeElement.background_style}
        textPosition={homeElement.text_position || (homeElement.news_inverted ? 'inverted' : homeElement.news ? 'default' : null)}
        movie={homeElement.movie}
        useRegular={homeElement.use_regular}
        pillColor={homeElement.pill_color}
        pillBackground={homeElement.pill_background}
      />
      break
    case 'unique':
      const translations = AvailableLanguages.map(lang => ({language: lang, text: get(homeElement, `metadata.text_${lang}`, '')}))
      component = <HomeElementUniqueContainer
        {...props}
        bottomPadding={bottomPadding}
        homeElement={homeElement} 
        dataType={homeElement.data_source}
        link={homeElement.link}
        newTab={homeElement.new_tab}
        translations={translations}
        playOnHover={homeElement.play_on_hover}
        video={homeElement.video}
        image={(homeElement.data_source === 'image' && window.innerWidth < 650 && homeElement.mobileImage) ? homeElement.mobileImage : homeElement.image}
        videos={homeElement.videos}
        images={homeElement.images}
        spacing={homeElement.spacing}
        backgroundColor={homeElement.background_color}
        backgroundStyle={homeElement.background_style}
      />
      break;
    case 'event_grid':
      component = <HomeElementEventGrid
          {...props}
          homeElement={makeHomeElementProp(homeElement, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites)}
            trackType={makeTrackTypeProp(homeElement)}
            dataType={makeDataTypeProp(homeElement)}
            requestElement={makeRequestElementProp(homeElement)}
            itemComponent={homeElement.shape == "rectangle_event" ? HomeElementCarouselItem : (homeElement.grid_featured ? HomeElementFeaturedItem : GridItem)}
            home={true}
            linkTo={homeElement.link_to}
            preferences={homeElement.preferences}
        />
      break;
    default:
      component = null
  }

  // If the element does not have elements to show, dont display it unless its a banner.
  // Banners should still be shown as they will be rendered with another provided media.
  if (homeElement.type !== 'banner' && homeElement.type !== 'unique' && homeElement.items.data.length === 0) {
    component = null
  }
  setPageReady()

  return <ErrorBoundary>{component}</ErrorBoundary>
}

export default withStyles(pagesStyle)(Channel);

const DivR = styled.div`
  width: 100%;
  position: relative;
  float: left;
  overflow: hidden;
  
@media (max-width: 770px) {
  display: none;
}
`;